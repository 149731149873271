import axios from "axios";
import URLs from "../utils/urls";
import { HTTP_VERB } from "../utils/consts";
import { getApiOptions } from "../utils/apiHelpers";



export async function getMessagesApi(setResponse) {
    const ops = {
        method: HTTP_VERB.GET,
        url: `${URLs.MESSAGES}`,
        ...getApiOptions()
    };
    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function getUserMessagesApi(setResponse) {
    const ops = {
        method: HTTP_VERB.GET,
        url: `${URLs.MESSAGES}/user`,
        ...getApiOptions()
    };
    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}