/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import CoreOrderReportContext, {getDefaultContext} from './coreOrderReportContext';
import coreOrderReportReducer from './coreOrderReportReducer';
import { RESET_CORE_ORDER_REPORT_INFO, SET_CORE_ORDER_REPORT_INFO } from './coreOrderReportActionTypes';


const CoreOrderReportState = props => {
  const [coreOrderReportContext, dispatch] = useReducer(coreOrderReportReducer, getDefaultContext());
  const setCoreOrderReportInfo = coreOrderReportInfo => dispatch({ type: SET_CORE_ORDER_REPORT_INFO, payload: coreOrderReportInfo });
  const resetCoreOrderReportInfo = () => dispatch({ type: RESET_CORE_ORDER_REPORT_INFO, payload: null});

  return (
    <CoreOrderReportContext.Provider
      value={{
        ...coreOrderReportContext,
        setCoreOrderReportInfo,
        resetCoreOrderReportInfo
      }}
    >
      {props.children}
    </CoreOrderReportContext.Provider>
  );
};
CoreOrderReportState.propTypes = {
  children: PropTypes.object,
};

export default CoreOrderReportState;
