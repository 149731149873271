/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import AffiliateUserReportContext, {getDefaultContext} from './affiliateUserReportContext';
import affiliateUserReportReducer from './affiliateUserReportReducer';
import { RESET_AFFILIATE_USER_REPORT_INFO, SET_AFFILIATE_USER_REPORT_INFO } from './affiliateUserReportActionTypes';


const AffiliateUserReportState = props => {
  const [affiliateUserReportContext, dispatch] = useReducer(affiliateUserReportReducer, getDefaultContext());
  const setAffiliateReportInfo = affiliateReportInfo => dispatch({ type: SET_AFFILIATE_USER_REPORT_INFO, payload: affiliateReportInfo });
  const resetAffiliateReportInfo = () => dispatch({type: RESET_AFFILIATE_USER_REPORT_INFO, payload: null});

  return (
    <AffiliateUserReportContext.Provider
      value={{
        ...affiliateUserReportContext,
        setAffiliateReportInfo,
        resetAffiliateReportInfo
      }}
    >
      {props.children}
    </AffiliateUserReportContext.Provider>
  );
};
AffiliateUserReportState.propTypes = {
  children: PropTypes.object,
};

export default AffiliateUserReportState;
