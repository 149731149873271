import { getDefaultContext } from './coreOrderReportContext';
import { RESET_CORE_ORDER_REPORT_INFO, SET_CORE_ORDER_REPORT_INFO } from './coreOrderReportActionTypes';


const coreOrderReportReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_CORE_ORDER_REPORT_INFO:
      return {
        ...state,
        coreOrderReportInfo: {
          ...state.coreOrderReportInfo,
          ...payload
        }
      };
    case RESET_CORE_ORDER_REPORT_INFO:
      return{
        ...getDefaultContext()
      }
    default:
      return state;
  }
};

export { coreOrderReportReducer as default };
