import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls";


export async function getUserAffiliatesApi(params,setResponse) {
    const ops = {
        method: 'get',
        url: `${URLs.AFFILIATES}`,
        ...getApiOptions(),
        params: params
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function getAffiliateStoresApi(affiliateId, setResponse) {
    const ops = {
        method: 'get',
        url: `${URLs.AFFILIATES}/${affiliateId}/stores`,
        ...getApiOptions()
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function getUserAffiliateDetailsApi(affiliateId, setResponse) {
    const ops = {
        method: 'get',
        url: `${URLs.AFFILIATES}/${affiliateId}`,
        ...getApiOptions()
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function isEligibleToSyncAffiliates(setResponse) {
    const ops = {
        method: 'get',
        url: `${URLs.AFFILIATES}/sync/required`,
        ...getApiOptions()
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function synchronizeAffiliates(setResponse) {
    const ops = {
        method: 'put',
        url: `${URLs.AFFILIATES}/synchronize`,
        ...getApiOptions()
    };

    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}