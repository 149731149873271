import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls";

export async function getUserTypesApi(setResponse) {
    const ops = {
        method: 'get',
        url: URLs.GET_USER_TYPES,
        ...getApiOptions()
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}