import React from 'react'
import { Button as MuiButton } from '@mui/material';


function Button(props) {
    const { text, size, color, variant, onClick, ...other } = props

    return (
        <MuiButton fullWidth
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            sx={{ height: '3.5em', fontSize:"16px" }}
            {...other}>
            {text}
        </MuiButton>
    )
}

export default Button;