import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { Outlet} from 'react-router-dom';
import SideBarMenu from "../components/header/sideBarMenu";


function Reports() {
    
     return (
        <>
            <SideBarMenu selectedOption="Reports" />
            <Breadcrumbs separator="|" sx={{ marginTop: '0', marginBottom: '1em' }}>
                <Link href="/home" underline="hover" sx={{ color: "#003561", fontSize: '14px', padding: '0' }}>Home</Link>
                <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Reports</Link>
            </Breadcrumbs>
            <Outlet />
 </>
 );}

export default Reports;