import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import { HTTP_VERB } from "../utils/consts";
import URLs from "../utils/urls";

export async function createUserApi(payload, setResponse) {
    const ops = {
        method: HTTP_VERB.POST,
        url: URLs.GET_USERS,
        ...getApiOptions(),
        data: payload
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function updateUserApi(payload, setResponse) {
    const ops = {
        method:HTTP_VERB.PUT,
        url: URLs.GET_USERS,
        ...getApiOptions(),
        data: payload
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function checkUserNameIfAvailable(username, setResponse) {
    const ops = {
        method: HTTP_VERB.GET,
        url: `${URLs.CHECK_USERNAME_AVAILABLE}/${username }`,
        ...getApiOptions(),
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function getUsersApi(params, setResponse) {
    const ops = {
        method: HTTP_VERB.GET,
        url: URLs.GET_USERS,
        ...getApiOptions(),
        params: params
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function getUserDetailApi(userId, setResponse) {
    const ops = {
        method: HTTP_VERB.GET,
        url: `${URLs.GET_USERS}/${userId}`,
        ...getApiOptions()
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        setResponse(error);
    }
}

export async function resetUserPasswordApi(userId, setResponse) {
    const ops = {
        method: HTTP_VERB.POST,
        url: `${URLs.GET_USERS}/${userId}/resetPassword`,
        ...getApiOptions()
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function updateUserStatus(userId,payload, setResponse) {
    const ops = {
        method: HTTP_VERB.PUT,
        url: `${URLs.GET_USERS}/${userId}/status`,
        ...getApiOptions(),
        data: payload
    };
    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}