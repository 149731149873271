import { Alert, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Input, Password } from '../components/formFields';
import { useForm } from '../utils/hooks/useForm';
import { MainTitle, FormText } from '../components/styledComponets/Titles';
import { getForgotPasswordQuestion, resetUserPassword } from "../api/accountApi";
import { useNavigate } from 'react-router';
import { ROUTES } from '../utils/consts';
import useGlobalMessenger from '../utils/hooks/useGlobalMessenger';
import { FormLabel } from '../components/styledComponets/Labels';
import useRequest from '../utils/hooks/useRequest';

function ForgotPassword() {
    const navigate = useNavigate();
    const { setGlobalMessage } = useGlobalMessenger();

    const [apiGetResponse, setApiGetResponse] = useState({});
    const [apiPostResponse, setApiPostResponse] = useState(null);

    const [canUserResetPassword, setCanUserResetPassword] = useState(true);
    const getForgotPasswordQuestionReq = useRequest(getForgotPasswordQuestion);
    const resetUserPasswordReq = useRequest(resetUserPassword);

    const initialFormValue = {
        activeStep: 0,
        userName: "",
        securityQuestion: "",
        securityAnswer: ""
    };

    const validateUserName = (fieldValues = values) => {
        let temp = { ...errors }
        if ('userName' in fieldValues)
            temp.userName = fieldValues.userName ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(initialFormValue, true, validateUserName);

    const handleUsernameSubmit = (e) => {
        if (validateUserName()) {
            getForgotPasswordQuestionReq.request(values.userName, setApiGetResponse);
        }
    };

    useEffect(() => {
        if (apiGetResponse?.data) {
            if (apiGetResponse?.data?.securityQuestion) {
                setValues({
                    ...values,
                    securityQuestion: apiGetResponse?.data?.securityQuestion,
                    activeStep: 1
                });
            } else {
                setCanUserResetPassword(false);
            }
        }
    }, [apiGetResponse]);

    const validateSecurityAnswer = (fieldValues = values) => {
        let temp = { ...errors }
        if ('securityAnswer' in fieldValues)
            temp.securityAnswer = fieldValues.securityAnswer ? "" : "This field is required."
        setErrors({
            ...temp
        })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const handleResetPasswordSubmit = (e) => {
        if (validateSecurityAnswer()) {
            resetUserPasswordReq.request(values, setApiPostResponse);
        }
    };

    useEffect(() => {
        if (apiPostResponse?.data) {
            resetForm();
            setGlobalMessage({ text: apiPostResponse?.data });
            setTimeout(() => navigate(ROUTES.LOGIN_ROUTE), 10);
        }
    }, [apiPostResponse]);

    const handleBackToLogin = () => {
        navigate(ROUTES.LOGIN_ROUTE);
    }

    return (
        <>
            <Grid sx={{ marginBottom: "1em" }}>
                {!canUserResetPassword &&
                    <Alert sx={{ margin: "0 -4.6em 0 -6.9em" }} severity="error" variant="filled" onClose={(e) => { setCanUserResetPassword(true); }}>
                        Recovery information unavailable. Please contact your <strong>supervisor</strong>, or email your <strong>Charter Point of Contact</strong> to reset your account password.
                    </Alert>}
            </Grid>
            <Grid container spacing={1} rowSpacing={3} columnSpacing={4} sx={{ marginTop: '2em', opacity: !canUserResetPassword ? 0.5 : 1 }}>
                {values.activeStep === 0 ? (
                    <>
                        <Grid item md={5} sm={11} >
                            <MainTitle>Forgot Password</MainTitle>
                            <hr />
                        </Grid><Grid item md={5} sm={1} />
                        <Grid item md={3} sm={8}>
                            <FormLabel>Username</FormLabel>
                            <Input name="userName" value={values.userName} onChange={handleInputChange}
                                error={errors.userName} />
                        </Grid><Grid item md={8} sm={3} />
                        <Grid item md={5} sm={11} >
                            <hr />
                        </Grid><Grid item md={6} sm={1} />
                        <Grid item md={2} sm={4}>
                            <Button disabled={getForgotPasswordQuestionReq.inprogress} text="Submit" onClick={handleUsernameSubmit} />
                        </Grid>
                        <Grid item md={2} sm={4}>
                            <Button text="Back to Login" onClick={handleBackToLogin} variant="outlined" />
                        </Grid>
                    </>) : (
                    <>
                        <Grid item md={5} sm={11} >
                            <MainTitle>Forgot Password</MainTitle>
                            <hr />
                            <Grid item sm={6} sx={{ marginTop: '1em' }}>
                                <FormLabel>Username</FormLabel><br/>
                                <FormLabel sx={{fontSize:"14px !important"}}>{values.userName}</FormLabel>
                            </Grid><Grid item sm={6} />
                        </Grid><Grid item md={6} sm={1} />
                        <Grid item md={5} sm={11} >
                            <Typography variant='subtitle2'>
                                You must correctly answer your security question to have a new password emailed to you.
                            </Typography>
                        </Grid><Grid item md={6} sm={1} />
                        <Grid item md={5} sm={11} >
                            <Typography variant='subtitle2'>
                                {values.securityQuestion}
                            </Typography>
                        </Grid><Grid item md={5} sm />
                        <Grid item md={4} sm={8} >
                            <Password name="securityAnswer" value={values.securityAnswer} onChange={handleInputChange}
                                error={errors.securityAnswer} />
                        </Grid><Grid item md={4} sm={3} />
                        <Grid item md={7} sm={11} >
                            <hr />
                        </Grid><Grid item md={5} sm />
                        <Grid item md={3} sm={4}>
                                <Button disabled={resetUserPasswordReq.inprogress} onClick={handleResetPasswordSubmit} text="Submit" />
                        </Grid>
                        <Grid item md={3} sm={4}>
                            <Button onClick={handleBackToLogin} text="Back to Login" variant="outlined" />
                        </Grid>
                    </>)}

            </Grid>
        </>);
}

export default ForgotPassword;