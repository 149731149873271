import { FormControlLabel, Grid, Paper, Radio, RadioGroup, TablePagination, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getUserAffiliatesApi } from "../../api/affiliateStoreApi";
import { getBuyflowUrlApi } from "../../api/buyflowApi";
import useGlobalMessenger from "../../utils/hooks/useGlobalMessenger";
import Button from "../formFields/Button"
import useRequest from "../../utils/hooks/useRequest";
import { FormLabel, FormValue } from "../styledComponets/Labels";
import { SectionContainer } from "../styledComponets/Containers";
import { SubTitle } from "../styledComponets/Titles";

import GlobalContext from "../../context/globalContext/globalContext";
import SelectedRadioButton from "./custom/SelectedRadioButton";
import SearchControl from "../formFields/SearchControl";
import Form from "../formFields/Form";

function LaunchBuyflow() {
    const { launchBuyflowInfo, setLaunchBuyflowInfo } = useContext(GlobalContext);

    const [userAffiliateApiResp, setUserAffiliateApiResp] = useState({});
    const [affiliates, setAffiliates] = useState();
    const [affiliatePage, setAffiliatePage] = useState(0);
    const [affiliatesPerPage, setAffilaitesPerPage] = useState(12);
    const [affiliateSearchCriteria, setAffiliateSearchCriteria] = useState('');
    const [totalAffiliatesCount, setTotalAffiliatesCount] = useState(0);
    const [selectedAffiliate, setSelectedAffiliate] = useState();
    const [stores, setStores] = useState();
    const [selectedStore, setSelectedStore] = useState(null);
    const [storePage, setStorePage] = useState(0);
    const [storesPerPage, setStoresPerPage] = useState(16);
    const [storeSearchCriteria, setStoreSearchCriteria] = useState('');
    const [totalStoresCount, setTotalStoresCount] = useState(0);

    const [showAffiliateLabelView, setShowAffiliateLableView] = useState(false);

    const [buyflowResp, setBuyflowResp] = useState([]);
    const [enableLauchBuyflow, setEnableLauchBuyflow] = useState(true);

    const getUserAffiliatesApiReq = useRequest(getUserAffiliatesApi, !launchBuyflowInfo?.selectedAffiliate?.affiliateId);
    const getBuyflowUrlApiReq = useRequest(getBuyflowUrlApi);

    const loadAffiliates = () => {
        if (!getUserAffiliatesApiReq.inprogress) {
            getUserAffiliatesApiReq.request({ page: (parseInt(affiliatePage, 0) + 1), limit: affiliatesPerPage, affiliateName: affiliateSearchCriteria, includeStores:true }, setUserAffiliateApiResp);
        }
    }

    useEffect(() => {
        if (launchBuyflowInfo?.selectedAffiliate) {
            setSelectedAffiliate(launchBuyflowInfo?.selectedAffiliate);
            setTotalAffiliatesCount(launchBuyflowInfo?.totalAffiliatesCount);
            if (launchBuyflowInfo.selectedStore) {
                setSelectedStore(launchBuyflowInfo.selectedStore);
            }
        }
    }, []);

    useEffect(() => {
        if (!selectedAffiliate) {
            loadAffiliates();
        }
    }, [selectedAffiliate, affiliatesPerPage, affiliatePage, affiliateSearchCriteria]);

    useEffect(() => {
        if (userAffiliateApiResp?.data) {
            setAffiliates(userAffiliateApiResp?.data);
            setTotalAffiliatesCount(userAffiliateApiResp?.pageInfo?.totalRecords)
            if (userAffiliateApiResp?.pageInfo?.totalRecords === 1 && affiliateSearchCriteria?.length === 0) {
                setSelectedAffiliate(userAffiliateApiResp?.data[0])
            }
        } else {
            setAffiliates([]);
            setTotalAffiliatesCount(0)
        }
    }, [userAffiliateApiResp]);

    const handleAffiliateRowsPerPageChange = (event) => {
        setAffilaitesPerPage(parseInt(event.target.value, 0));
        setAffiliatePage(0);
    };

    const handleAffiliateSearchClick = (searchText) => {
        setAffiliatePage(0);
        setAffiliateSearchCriteria(searchText);
    }

    const handleAffiliateSearchResetClick = () => {
        setAffiliatePage(0);
        setAffiliateSearchCriteria('');
    }
    const handleAffiliateSelect = (e) => {
        setSelectedAffiliate(affiliates?.find(a => a.affiliateId == e.target.value));
    };

    useEffect(() => {
        setStorePage(0);
        setStoresByPage();
        if (selectedAffiliate?.stores) {
            if (selectedAffiliate?.stores.length === 1) {
                setSelectedStore(selectedAffiliate?.stores[0]);
                setShowAffiliateLableView(totalAffiliatesCount === 1 && affiliateSearchCriteria?.length === 0);
            }
            setTotalStoresCount(selectedAffiliate?.stores.length);
        } else {
            setShowAffiliateLableView(totalAffiliatesCount === 1 && affiliateSearchCriteria?.length === 0);
        }
    }, [selectedAffiliate, totalAffiliatesCount]);

    const setStoresByPage = (page) => {
        let tempStores = [];
        if (storeSearchCriteria?.length > 0) {
            tempStores = selectedAffiliate?.stores?.filter(s =>
                Object.keys(s).some(i => s[i].toLowerCase().includes(storeSearchCriteria.toLocaleLowerCase())));
        } else {
            tempStores = selectedAffiliate?.stores;
        }
        let startIndex = ((page ?? 0) * storesPerPage);
        setStores(tempStores?.slice(startIndex, startIndex + storesPerPage));
    };

    useEffect(() => {
        setStoresByPage(storePage);
    }, [storePage, storesPerPage, storeSearchCriteria]);

    const handleStoreSearchClick = (searchText) => {
        setStorePage(0);
        setStoreSearchCriteria(searchText);
    }

    const handleStoreSearchResetClick=()=>{
        setStorePage(0);
        setStoreSearchCriteria('');
    }

    const handleStoreSelect = (e) => {
        setSelectedStore(stores?.find(s => s.storeId == e.target.value));
    };

    const handleResetAffiliateSelected = () => {
        setSelectedStore(null);
        setSelectedAffiliate(null);
    }

    const handleResetStoreSelected = () => {
        setSelectedStore(null);
    }

    useEffect(() => {
        validateForm();
        setLaunchBuyflowInfo({
            ...launchBuyflowInfo,
            selectedAffiliate: selectedAffiliate,
            selectedStore: selectedStore,
            totalAffiliatesCount: totalAffiliatesCount
        });
    }, [selectedAffiliate, selectedStore, affiliates]);

    const validateForm = () => {
        if (!selectedAffiliate) {
            setEnableLauchBuyflow(false);
        } else if (selectedAffiliate?.stores?.length > 0 && !selectedStore) {
            setEnableLauchBuyflow(false);
        }
        else {
            setEnableLauchBuyflow(true);
        }
    }

    const handleLaunchBuyflow = (e) => {
        getBuyflowUrlApiReq.request(selectedAffiliate.affiliateId, selectedStore?.storeId, setBuyflowResp);
    }

    const { setGlobalMessage } = useGlobalMessenger();
    useEffect(() => {
        if (!buyflowResp?.errors && buyflowResp?.data?.buyflowUrl) {
            window.open(buyflowResp.data.buyflowUrl, '_blank');
        }
    }, [buyflowResp]);

    return (
        <Grid container>
            <Grid item md={12} sm={12}>
                {showAffiliateLabelView ? (
                    <SectionContainer variant="outlined">
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12}>
                                <FormLabel>Your Affiliate</FormLabel><br />
                                <FormValue>{selectedAffiliate?.affiliateName}</FormValue>
                            </Grid>
                            {selectedStore && <Grid item md={12} sm={12}>
                                <FormLabel>Your Store</FormLabel><br />
                                <FormValue>{selectedStore.storeName}</FormValue>
                            </Grid>
                            }
                        </Grid>
                    </SectionContainer>) : (<>
                        <SectionContainer variant="outlined">
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormLabel>Select Affiliate</FormLabel>
                                    {(totalAffiliatesCount ?? 0) > 1 &&
                                        <SubTitle>Total Affiliates - {totalAffiliatesCount ?? 0}</SubTitle>}
                                </Grid>
                                <Grid item sx={{ margin: "0.1em 0 0 1em" }}>
                                    <Form>
                                        <SearchControl placeholderText="Search affiliate" onSearch={handleAffiliateSearchClick} onReset={handleAffiliateSearchResetClick} />
                                    </Form>
                                </Grid>
                                <Grid item sm md />
                                <Grid item md={12} sm={12}>
                                    {selectedAffiliate ? (
                                        <SelectedRadioButton name="selectedAffilaiteRadio" value={selectedAffiliate.affiliateId} label={selectedAffiliate.affiliateName} showReset={totalAffiliatesCount > 1 || affiliateSearchCriteria?.length > 0} onReset={handleResetAffiliateSelected} resetLabel="Deselect and Show All" />
                                    ) : (
                                        <Grid container rowSpacing={1}>
                                            <Grid item md={12} sm={12}>
                                                <RadioGroup onChange={handleAffiliateSelect}>
                                                    <Grid container>
                                                        {affiliates?.map(item => (
                                                            <Grid item sm={6} md={4} key={item.affiliateId}>
                                                                <Paper key={item.affiliateId} sx={{
                                                                    padding: '.4em 1em',
                                                                    margin: '.4em',
                                                                    bgcolor: '#ffffff !important'
                                                                }}>
                                                                    <FormControlLabel control={<Radio />}
                                                                        label={<Typography sx={{ fontSize: "14px", color: "#003561" }}>{item.affiliateName}</Typography>}
                                                                        value={item.affiliateId} />
                                                                </Paper>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>
                                            {((totalAffiliatesCount ?? 1) > 12) &&
                                                <Grid item md={12} sm={12}>
                                                    <TablePagination component="div" sx={{ bgcolor: 'rgba(93, 184, 252, 0.1)', padding: '0em 0 4em 0 ', borderRadius: '10px' }}
                                                        count={totalAffiliatesCount ?? 0} page={affiliatePage}
                                                        rowsPerPage={affiliatesPerPage}
                                                        onPageChange={(e, value) => { setAffiliatePage(value) }}
                                                        rowsPerPageOptions={[12, 15, 30, 60]}
                                                        onRowsPerPageChange={handleAffiliateRowsPerPageChange}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </SectionContainer>
                        {selectedAffiliate && (totalStoresCount ?? 0) > 0 && stores &&
                            <SectionContainer variant="outlined">
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormLabel>Select Store</FormLabel><br />
                                        {(totalStoresCount ?? 0) > 1 &&
                                            <SubTitle>Total Stores - {totalStoresCount ?? 0}</SubTitle>}
                                    </Grid>
                                    <Grid item sx={{ margin: "0.1em 0 0 1em" }}>
                                        <Form>
                                            <SearchControl placeholderText="Search stores" onSearch={handleStoreSearchClick} onReset={handleStoreSearchResetClick} />
                                        </Form>
                                    </Grid>
                                    <Grid item sm md />
                                    <Grid item md={12} sm={12}>
                                        {selectedStore ? (
                                            <SelectedRadioButton name="selectedStoreRadio" value={selectedStore.storeId} label={selectedStore.storeName} showReset={totalStoresCount > 1} onReset={handleResetStoreSelected} resetLabel="Deselect and Show All" />
                                        ) : (
                                            <Grid container rowSpacing={1}>
                                                <Grid item md={12} sm={12}>
                                                    <RadioGroup onChange={handleStoreSelect}>
                                                        <Grid container>
                                                            {stores?.map(item => (
                                                                <Grid item sm={6} md={3} key={item.storeId}>
                                                                    <Paper key={item.storeId} sx={{
                                                                        padding: '.4em 1em',
                                                                        margin: '.4em'
                                                                    }}>
                                                                        <FormControlLabel sx={{ fontWeight: "bolder", color: "#003561" }} control={<Radio />}
                                                                            label={<Typography sx={{ fontSize: "14px", color: "#003561" }}>{item.storeName}</Typography>}
                                                                            value={item.storeId} />
                                                                    </Paper>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </RadioGroup>
                                                </Grid>
                                                {((totalStoresCount ?? 1) > 16) &&
                                                    <Grid item md={12} sm={12}>
                                                        <TablePagination component="div" sx={{ bgcolor: 'rgba(93, 184, 252, 0.1)', padding: '0em 0 4em 0 ', borderRadius: '10px' }}
                                                            count={totalStoresCount ?? 0} page={storePage}
                                                            rowsPerPage={storesPerPage}
                                                            onPageChange={(e, value) => { setStorePage(value) }}
                                                            rowsPerPageOptions={[8, 12, 16, 32, 64]}
                                                            onRowsPerPageChange={(e) => setStoresPerPage(e.target.value)}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </SectionContainer>}
                    </>)
                }

            </Grid>
            <Grid item md={4} sm={6} sx={{ margin: '1em 0' }}>
                <Button disabled={!enableLauchBuyflow} onClick={handleLaunchBuyflow} text="Launch Buyflow" />
            </Grid>
        </Grid >
    );
}
export default LaunchBuyflow;