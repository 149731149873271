import React, { useContext, useEffect, useState } from "react";
import {
    Box, Button, Breadcrumbs, Grid, IconButton, InputAdornment, Link, Table, TableBody,
    TableCell, TableContainer, TablePagination, TableRow, TextField, Tooltip
} from "@mui/material";
import { Form, FormSwitch } from "../components/formFields";
import SideBarMenu from "../components/header/sideBarMenu";
import { MainTitle, SubTitle } from "../components/styledComponets/Titles";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import SearchIcon from '@mui/icons-material/Search';

import globalContext from "../context/globalContext/globalContext";
import { getUserAffiliatesApi, isEligibleToSyncAffiliates, synchronizeAffiliates } from "../api/affiliateStoreApi";
import useRequest from "../utils/hooks/useRequest";
import useGlobalMessenger from "../utils/hooks/useGlobalMessenger";
import ModalPopup from "../components/styledComponets/ModalPopup";
import ViewAffiliateDetails from "../components/affiliateManagement/ViewAffiliateDetails";
import { UnknownLabel } from "../components/styledComponets/Labels";
import affiliateManagementContext from "../context/affiliateManagementContext/affiliateManagementContext";

function Affiliates() {
    const { userInfo } = useContext(globalContext);
    const { affiliateManagementInfo, setAffiliateManagementInfo } = useContext(affiliateManagementContext)

    const [apiResponse, setApiResponse] = useState({});
    const [affiliates, setAffiliates] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(() => {
        const saved = affiliateManagementInfo.rowsPerPage;
        return saved ? saved : 10
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchText, setSearchText] = useState(() => {
        const saved = affiliateManagementInfo.searchCriteria;
        return saved ? saved : "";
    });
    const [searchCriteria, setSearchCriteria] = useState(() => {
        const saved = affiliateManagementInfo.searchCriteria;
        return saved ? saved : "";
    });
    const [syncAffiliateAvailable, setSyncAffiliateAvailable] = useState(false);
    const [syncAffilaiteResponse, setSyncAffilaiteResponse] = useState(false);

    const getUserAffiliatesApiReq = useRequest(getUserAffiliatesApi);
    const isEligibleToSyncAffiliatesReq = useRequest(isEligibleToSyncAffiliates, false);
    const synchronizeAffiliatesApiReq = useRequest(synchronizeAffiliates);

    useEffect(() => {
        getUserAffiliatesApiReq.request({ page: (parseInt(page, 0) + 1), limit: rowsPerPage, affiliateName: searchCriteria, includeInactive: true }, setApiResponse);
    }, [rowsPerPage, page, searchCriteria]);

    useEffect(() => {
        setAffiliates(apiResponse?.data);
        setTotalRecords(apiResponse?.pageInfo?.totalRecords);
    }, [apiResponse]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setAffiliateManagementInfo({
            ...affiliateManagementInfo,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearchClick = (e) => {
        e.preventDefault();
        setPage(0);
        setSearchCriteria(searchText);
        setAffiliateManagementInfo({
            ...affiliateManagementInfo,
            searchCriteria: searchText
        })
    }

    useEffect(() => {
        isEligibleToSyncAffiliatesReq.request(setSyncAffiliateAvailable);
    }, []);

    const handleAffiliateSynchronizeClick = (e) => {
        synchronizeAffiliatesApiReq.request(setSyncAffilaiteResponse);
    };

    const { setGlobalMessage } = useGlobalMessenger();
    useEffect(() => {
        if (syncAffilaiteResponse?.data) {
            setGlobalMessage({ text: syncAffilaiteResponse?.data });
            setSyncAffiliateAvailable(false);
        }
    }, [syncAffilaiteResponse]);

    const [openAffiliateDetails, setOpenAffiliateDetails,] = useState(false);
    const [affiliateDetailId, setAffilaiteDetialId] = useState(0);
    const handleAffiliateDetailOpen = (affiliateId) => { setAffilaiteDetialId(affiliateId); setOpenAffiliateDetails(true); }

    return (
        <>
            <SideBarMenu selectedOption="Affiliates" />
            <>
                <Breadcrumbs separator="|" sx={{ marginTop: '0', marginBottom: '1em' }}>
                    <Link href="/home" underline="hover" sx={{ color: "#003561", fontSize: '14px', padding: '0' }}>Home</Link>
                    <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Affiliates</Link>
                </Breadcrumbs>
                <Grid container spacing={1}>
                    <Grid item >
                        <MainTitle>Manage Affiliates</MainTitle>
                        <SubTitle>{totalRecords ?? 0} Affiliates</SubTitle>
                    </Grid><Grid item sm></Grid>
                    <Grid item>
                        {userInfo.roles?.includes("sync_affiliates") &&
                            <Button text="Sync Affiliates" variant="contained" onClick={handleAffiliateSynchronizeClick} startIcon={<SyncOutlinedIcon />} disabled={!syncAffiliateAvailable}>Sync Affiliates</Button>
                        }
                    </Grid>
                    <Grid item sm={12}>
                        <Form>
                            <input type="submit" onClick={handleSearchClick} style={{ visibility: "hidden", height: "0em", position: 'absolute', zIndex: '100' }} />
                            <Box sx={{ boxShadow: 0, backgroundColor: 'rgba(93, 184, 252, 0.1)', padding: '1.2em 1.5em', borderRadius: '.5em' }}>
                                <TextField fullWidth sx={{ backgroundColor: '#fff', borderRadius: '1em', }}
                                    defaultValue={affiliateManagementInfo.searchCriteria}
                                    placeholder="Search for affiliates by Id or name..." onChange={(e) => setSearchText(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><IconButton onClick={handleSearchClick}><PersonSearchOutlinedIcon /></IconButton></InputAdornment>,
                                        startAdornment: <InputAdornment position="start"><IconButton onClick={handleSearchClick}><SearchIcon /></IconButton></InputAdornment>,
                                    }}>
                                </TextField>
                            </Box>
                        </Form>
                    </Grid>
                    <Grid item sm={12}>
                        {affiliates && (<>
                            <TableContainer sx={{ padding: '1em 0em' }}>
                                <Table>
                                    <TableBody>
                                        {affiliates?.map(aff => (
                                            <TableRow key={aff.affiliateId}>
                                                <TableCell style={{ maxWidth: "0em", padding: '0.8em' }}><div className={aff?.isActive ? "active-user" : "inactive-user"} /></TableCell>
                                                <TableCell style={{ maxWidth: "10em" }}><Tooltip arrow title="Affiliate ID"><span>{aff?.affiliateId}</span></Tooltip></TableCell>
                                                <TableCell style={{ maxWidth: "10em" }}><Tooltip arrow title="Affiliate name"><span>{aff?.affiliateName}</span></Tooltip></TableCell>
                                                <TableCell style={{ maxWidth: "10em" }}>
                                                    <Tooltip arrow title="Affiliate start date">
                                                        <span>
                                                            {new Date(aff?.startDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell style={{ maxWidth: "10em" }}>
                                                    {aff?.endDate ? <Tooltip arrow title="Affiliate end date"><span>{new Date(aff?.endDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span></Tooltip> : <UnknownLabel>Unavailable</UnknownLabel>}
                                                </TableCell>
                                                <TableCell style={{ maxWidth: "4em" }} >
                                                    <Tooltip arrow title="View affiliate details">
                                                        <IconButton onClick={() => handleAffiliateDetailOpen(aff?.affiliateId)} sx={{ color: "#003561" }}>
                                                            <VisibilityOutlinedIcon value={aff?.affiliateId} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>)
                        }
                    </Grid>
                    <Grid item sm={12}>
                        <TablePagination component="div"
                            count={totalRecords ?? 0} page={page}
                            rowsPerPage={rowsPerPage} sx={{ bgcolor: 'rgba(93, 184, 252, 0.1)', padding: '0em 0 4em 0 ', borderRadius: '10px' }}
                            onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                    <ModalPopup title="Affiliate Details" maxWidth="lg" open={openAffiliateDetails} onClose={() => setOpenAffiliateDetails(false)} innerComponent={<ViewAffiliateDetails affiliateId={affiliateDetailId}/>} />
                </Grid>

            </>
        </>
    );
}

export default Affiliates;