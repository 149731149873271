import React from 'react'
import { FormControlLabel, Grid, Radio as MuiRadio, Paper, RadioGroup, Typography } from '@mui/material';
import { LinkLabel } from '../../styledComponets/Labels';

export default function SelectedRadioButton(props) {
    const { name, label, value, showReset, onReset, resetLabel ="Reset" } = props;


    return (
        <Paper sx={{ padding: '.4em 1em', maxWidth: "sm", border: "solid 1px #0062b2;", borderRadius: "10px" }}>
            <Grid container>
                <Grid item sm>
                    <RadioGroup defaultValue={value}>
                        <FormControlLabel sx={{ fontWeight: "bolder", color:"#003561" }} name={name} value={value} control={<MuiRadio checked />} label={<Typography sx={{ fontWeight: "bold", margin: "0.7em" }} variant='button'>{label}</Typography>} />
                    </RadioGroup>
                </Grid>
                <Grid item sx={{ margin: '1em 1em 0 0' }}>
                    {showReset && <LinkLabel onClick={onReset} >{resetLabel}</LinkLabel>}
                </Grid>
            </Grid>
        </Paper>
    )
}