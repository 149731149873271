import React, { useContext, useEffect, useState } from "react";
import {
    Avatar, Grid, Table, TableBody, TableCell, TableContainer, Button,
    TablePagination, TableRow, Tooltip, TextField,
    InputAdornment, Box, IconButton, Breadcrumbs, Link
} from "@mui/material";
import SideBarMenu from "../components/header/sideBarMenu";
import { MainTitle, SubTitle } from "../components/styledComponets/Titles";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CreateEditUser from "../components/userManagement/createUser/CreateEditUser";
import ViewUser from "../components/userManagement/viewUser/ViewUser";
import { getUsersApi } from "../api/usersApi";
import ModalPopup from "../components/styledComponets/ModalPopup";
import { Form } from "../components/formFields";
import globalContext from "../context/globalContext/globalContext";
import useRequest from "../utils/hooks/useRequest";
import userManagementContext from "../context/userManagementContext/userManagementContext";
import configContext from "../context/configContext/configContext";

function Users() {
    const { userInfo } = useContext(globalContext);
    const { configInfo } = useContext(configContext);
    const { userManagementInfo, setUserManagementInfo } = useContext(userManagementContext);

    function getUserStatusClassName(status) {
        if (status === 'Active') {
            return "active-user";
        }
        return "inactive-user";
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(() => {
        const saved = userManagementInfo.rowsPerPage;
        return saved ? saved : 10;
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [apiResponse, setApiResponse] = useState({});
    const [searchText, setSearchText] = useState(() => {
        const saved = userManagementInfo.searchCriteria;
        return saved ? saved : "";
    });
    const [searchCriteria, setSearchCriteria] = useState(() => {
        const saved = userManagementInfo.searchCriteria;
        return saved ? saved : "";
    });
    const [users, setUsers] = useState([]);
    const [viewUserId, setViewUserId] = useState(0);
    const [editUserId, setEditUserId] = useState(0);
    const [refreshTime, setRefreshTime] = useState();

    const getUsersApiReq = useRequest(getUsersApi);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setUserManagementInfo({
            ...userManagementInfo,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };

    useEffect(() => {
        getUsersApiReq.request({ page: (parseInt(page, 0) + 1), limit: rowsPerPage, firstName: searchCriteria }, setApiResponse);
    }, [rowsPerPage, page, searchCriteria, refreshTime]);

    useEffect(() => {
        setUsers(apiResponse?.data);
        setTotalRecords(apiResponse?.pageInfo?.totalRecords);
    }, [apiResponse]);

    const [openCreateUser, setOpenCreateUser] = React.useState(false);
    const handleAddUserOpen = () => setOpenCreateUser(true);
    const handleAddUserClose = (user) => { setOpenCreateUser(false); setRefreshTime(Date.now); }

    const [openViewUser, setOpenViewUser] = React.useState(false);
    const handleViewUserOpen = (userId) => { setViewUserId(userId); setOpenViewUser(true); }
    const handleViewUserClose = () => { setOpenViewUser(false); setRefreshTime(Date.now); }

    const [openEditUser, setOpenEditUser] = React.useState(false);
    const handleEditUserOpen = (userId) => { setEditUserId(userId); setOpenEditUser(true); }
    const handleEditUserClose = () => { setOpenEditUser(false); setRefreshTime(Date.now); }

    const handleSearchClick = (e) => {
        e.preventDefault();
        setPage(0);
        setSearchCriteria(searchText);
        setUserManagementInfo({
            ...userManagementInfo,
            searchCriteria: searchText
        })
    }

    return (
        <>
            <SideBarMenu selectedOption="Users" />
            <Breadcrumbs separator="|" sx={{ marginTop: '0', marginBottom: '1em' }}>
                <Link href="/home" underline="hover" sx={{ color: "#003561", fontSize: '14px', padding: '0' }}>Home</Link>
                <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Users</Link>
            </Breadcrumbs>
            <Grid container spacing={1}>
                <Grid item >
                    <MainTitle>Manage Users</MainTitle>
                    <SubTitle>{totalRecords??0} Users</SubTitle>
                </Grid><Grid item sm></Grid>
                <Grid item>
                    {userInfo.roles?.includes("manage_users") &&
                        <Button text="+ Create New User" onClick={handleAddUserOpen} variant="contained">+ Create New User</Button>
                    }
                </Grid>
                <Grid item sm={12}>
                    <Form>
                        <input type="submit" onClick={handleSearchClick} style={{ visibility: "hidden", height: "0em", position: 'absolute', zIndex: '100' }} />
                        <Box sx={{ boxShadow: 0, backgroundColor: 'rgba(93, 184, 252, 0.1)', padding: '1.2em 1.5em', borderRadius: '.5em' }}>
                            <TextField fullWidth sx={{ backgroundColor: '#fff', borderRadius: '1em', }}
                                placeholder="Search for users by username, first name, last name, or email" onChange={(e) => setSearchText(e.target.value)}
                                defaultValue={userManagementInfo.searchCriteria}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><IconButton onClick={handleSearchClick}><PersonSearchOutlinedIcon /></IconButton></InputAdornment>,
                                    startAdornment: <InputAdornment position="start"><IconButton onClick={handleSearchClick}><SearchIcon /></IconButton></InputAdornment>,
                                }}>
                            </TextField>
                        </Box>
                    </Form>
                </Grid>
                <Grid item sm={12}> {users &&<>
                    <TableContainer sx={{ padding: '1em 0em' }}>
                        <Table>
                            <TableBody>
                                {users?.map(user => (
                                    <TableRow key={user.userId}>
                                        <TableCell style={{ maxWidth: "0em", padding: '0.8em' }}><div className={getUserStatusClassName(user.status)}></div></TableCell>
                                        <TableCell style={{ maxWidth: "4em", paddingLeft: '1em' }}><Avatar sx={{ width: 32, height: 32, bgcolor: "#0062b2" }} variant="rounded" alt={user.firstName} /></TableCell>
                                        <TableCell style={{ padding: '0em' }}>
                                            <Grid container justifyContent="left" columnGap={2}>
                                                <Grid item md={4} sm={12}><Tooltip arrow title="Last name, First name"><span>{user.lastName}, {user.firstName}</span></Tooltip></Grid>
                                                <Grid item md={7} sm={12}><Tooltip arrow title="Email"><span>{user.email}</span></Tooltip></Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{ maxWidth: "20em" }}>
                                            <Grid container justifyContent="left" columnGap={3}>
                                                <Grid item md={6} sm={12}><Tooltip arrow title="Username"><span>{user.userName}</span></Tooltip></Grid>
                                                {   configInfo.AllowExplicitAgentId && 
                                                    <Grid item md={4} sm={12}><Tooltip arrow title="Affiliate agent ID"><span>{user.affiliateAgentId}</span></Tooltip></Grid>
                                                }
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{ maxWidth: "10em" }}><Tooltip arrow title="User type"><span>{user.userType?.name}</span></Tooltip></TableCell>
                                        <TableCell style={{ maxWidth: "4em" }}>
                                            <Tooltip arrow title="View user details">
                                                <IconButton onClick={(e) => handleViewUserOpen(user.userId)} sx={{ color: "#003561" }}>
                                                    <VisibilityOutlinedIcon value={user.userId} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        {userInfo.roles?.includes("manage_users") &&
                                            <TableCell style={{ maxWidth: "4em" }}>
                                                <Tooltip arrow title="Edit user">
                                                    <IconButton onClick={(e) => handleEditUserOpen(user.userId)} sx={{ color: "#003561" }}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
                </Grid>
                <Grid item sm={12}>
                    <TablePagination component="div"
                        count={totalRecords ??0} page={page}
                        rowsPerPage={rowsPerPage} sx={{ bgcolor: 'rgba(93, 184, 252, 0.1)', padding: '0em 0 4em 0 ', borderRadius: '10px' }}
                        onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
            <br />
            <ModalPopup title="Create new User" open={openCreateUser} onClose={handleAddUserClose} maxWidth="lg" innerComponent={<CreateEditUser onClose={handleAddUserClose} editMode={false} />} />
            <ModalPopup title="View User" open={openViewUser} onClose={handleViewUserClose} maxWidth="md" innerComponent={<ViewUser selectedUserId={viewUserId}/>} />
            <ModalPopup title="Edit User" open={openEditUser} onClose={handleEditUserClose} maxWidth="lg" innerComponent={<CreateEditUser title="Edit User" editMode={true} userId={editUserId} onClose={handleEditUserClose} />} />
        </>
    );
}

export default Users;