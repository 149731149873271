import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainTitle } from "../../styledComponets/Titles"
import { Button, Password, Select } from "../../formFields"
import { getSecurityQuestions, updateSecurityQuestions } from "../../../api/securityQuestionApi";
import { useForm } from "../../../utils/hooks/useForm";
import useGlobalMessenger from "../../../utils/hooks/useGlobalMessenger";
import { FormLabel } from "../../styledComponets/Labels";
import { ROUTES } from '../../../utils/consts';
import { useNavigate } from 'react-router';
import { clearAuthToken } from "../../../utils/authHelper";
import GlobalContext from "../../../context/globalContext/globalContext";
import { defaultUserInfo } from "../../../utils/globalStateHelper";
import { isLoggedIn } from "../../../utils/authHelper";
import useRequest from "../../../utils/hooks/useRequest";
import ModalPopup from "../../styledComponets/ModalPopup";
import { Stack } from "@mui/system";

function SecurityQuestion() {
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(GlobalContext);

    const [apiResponse, setApiResponse] = useState({});
    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [apiUpdateResponse, setApiUpdateResponse] = useState({});
    const [passwordConfirmOpen, setPasswordConfirmOpen] = useState(false);
    const { setGlobalMessage } = useGlobalMessenger();

    const getSecurityQuestionsApiReq = useRequest(getSecurityQuestions);
    const updateSecurityQuestionsApiReq = useRequest(updateSecurityQuestions);

    const initialFormValues = {
        questionId: 0,
        newSecurityQuestion: "",
        newSecurityAnswer: "",
        password: ""
    };
    const validateForm = (fieldValues = values) => {
        let temp = { ...errors }
        temp.password="";
        if ('newSecurityAnswer' in fieldValues)
            temp.newSecurityAnswer = fieldValues.newSecurityAnswer ? "" : "This field is required."
        if ('questionId' in fieldValues)
            temp.questionId = fieldValues.questionId == 0 ? "This field is required." : ""
        setErrors({
            ...temp
        })
        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        errors,
        setErrors,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(initialFormValues, true, validateForm);

    useEffect(() => {
        getSecurityQuestionsApiReq.request(setApiResponse);
    }, ["lock"]);

    useEffect(() => {
        setSecurityQuestions(apiResponse?.data);
    }, [apiResponse]);

    useEffect(() => {
        setValues({
            ...values,
            newSecurityQuestion: securityQuestions?.find(q => q.questionId == values.questionId)?.question
        });
    }, [values.questionId]);

    const handleSubmit = () => {
        if (validateForm()) {
            updateSecurityQuestionsApiReq.request(values, setApiUpdateResponse);
        }
    }

    useEffect(() => {
        if (apiUpdateResponse?.data) {
            resetForm();
            setPasswordConfirmOpen(false);
            setGlobalMessage({ text: apiUpdateResponse?.data });
            
            if (userInfo.userName || isLoggedIn()) {
                if (userInfo.nextAction == 'SECURITY_QUESTION_REQUIRED') {
                    clearAuthToken();
                    setUserInfo({ ...defaultUserInfo });
                    setTimeout(() => navigate(ROUTES.LOGIN_ROUTE), 10);
                }
            }

        } else if (apiUpdateResponse?.errors) {
            let temp = { ...errors };
            if ('password' in values)
                temp.password = apiUpdateResponse?.errors?.message

            setErrors({
                ...temp
            });
        }
    }, [apiUpdateResponse]);

    return (
        <Grid container spacing={1} sx={{ textAlign: "left", marginTop: '1em' }}>{/*TODO why to keep left align here!*/}
            <Grid item sm={12} >
                <MainTitle>Update Security Question</MainTitle>
                <hr />
            </Grid>
            <Grid item md={5} sm={8} sx={{ textAlign: "left" }} >
                <FormLabel id="selectSecurityQuestion">Select security question</FormLabel>
                <Select sx={{ width: '100%' }}
                    name="questionId"
                    label="Security Question"
                    value={values.questionId}
                    onChange={handleInputChange}
                    options={securityQuestions?.map(q => ({ id: q.questionId, title: q.question }))}
                    error={errors.questionId}>
                </Select>
            </Grid><Grid item md={6} />
            <Grid item md={4} sm={6}>
                <FormLabel>Answer to security question</FormLabel>
                <Password name="newSecurityAnswer" value={values.newSecurityAnswer} onChange={handleInputChange}
                    error={errors.newSecurityAnswer} />
            </Grid><Grid item sm={6} />
            <Grid item sm={12}><hr /></Grid>
            <Grid item md={4} sm={6}>
                <Button onClick={(e) => setPasswordConfirmOpen(validateForm())} text="Update Security Question" />
                <ModalPopup open={passwordConfirmOpen} onClose={(e) => setPasswordConfirmOpen(false)} maxWidth="sm"
                    innerComponent={
                        <Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={12} >
                                    <MainTitle>Enter Your Current Password</MainTitle>
                                </Grid>
                                <Grid item sm={12} >
                                    <FormLabel>Current password</FormLabel>
                                    <Password name="password" value={values.password} onChange={handleInputChange}
                                        error={errors.password} />
                                </Grid>
                                <Grid item sm={12} >
                                    <Stack direction='row' columnGap={2}>
                                        <Button text='Cancel' variant="outlined" onClick={(e) => setPasswordConfirmOpen(false)} />
                                        <Button disabled={!(values?.password && values.password.length >7) ||updateSecurityQuestionsApiReq.inprogress} text='Update Security Question' onClick={handleSubmit} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    } />
            </Grid><Grid item sm />
        </Grid>
    );
}

export default SecurityQuestion;