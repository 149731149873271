import { RESET_AFFILIATE_SEARCH_CRITERIA, SET_AFFILIATE_SEARCH_CRITERIA } from './affiliateManagementActionTypes';
import { getDefaultContext } from './affiliateManagementContext';


const affiliateManagementReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_AFFILIATE_SEARCH_CRITERIA:
      return {
        ...state,
        affiliateManagementInfo: {
          ...state.affiliateManagementInfo,
          ...payload
        }
      }
    case RESET_AFFILIATE_SEARCH_CRITERIA:
      return{
        ...getDefaultContext()
      }
    default:
      return state;
  }
};

export { affiliateManagementReducer as default };
