import React from 'react';

export const getDefaultContext = () => ({
  affiliateManagementInfo: {
    searchCriteria: "",
    rowsPerPage: null
  }
  
});

const setDefaultContext = {
  setAffiliateManagementInfo: () => {},
  resetAffiliateManagementInfo: () => {}
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });