/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import MobileOrderReportContext, {getDefaultContext} from './mobileOrderReportContext';
import mobileOrderReportReducer from './mobileOrderReportReducer';
import { RESET_MOBILE_ORDER_CONTEXT, SET_MOBILE_ORDER_REPORT_INFO } from './mobileOrderReportActionTypes';


const MobileOrderReportState = props => {
  const [mobileOrderReportContext, dispatch] = useReducer(mobileOrderReportReducer, getDefaultContext());
  const setMobileOrderReportInfo = mobileOrderReportInfo => dispatch({ type: SET_MOBILE_ORDER_REPORT_INFO, payload: mobileOrderReportInfo });
  const resetMobileOrderReportInfo = () => dispatch({ type: RESET_MOBILE_ORDER_CONTEXT, payload: null})

  return (
    <MobileOrderReportContext.Provider
      value={{
        ...mobileOrderReportContext,
        setMobileOrderReportInfo,
        resetMobileOrderReportInfo
      }}
    >
      {props.children}
    </MobileOrderReportContext.Provider>
  );
};
MobileOrderReportState.propTypes = {
  children: PropTypes.object,
};

export default MobileOrderReportState;
