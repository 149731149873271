import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton, Chip, chipClasses } from '@mui/material';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

export default function StatusChip(props) {

    const { label, status, onClick, ...other } = props;
    return (
        <Chip sx={{
            marginLeft: "2em", padding: ".2em .5em .2em 0", color: "white", backgroundColor: status == true ? "#008516" :"#9BA9BD", [`& .${chipClasses.icon}`]: {
                color: "#fff"
            }
        }} size="small" icon={<CircleRoundedIcon />} label={label} onClick={() => { onClick() }}
        {...other} />
    )
}