import { Avatar, CircularProgress, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { getUserDetailApi, resetUserPasswordApi, updateUserStatus } from '../../../api/usersApi';
import globalContext from '../../../context/globalContext/globalContext';
import useGlobalMessenger from '../../../utils/hooks/useGlobalMessenger';
import { Button, StatusChip } from '../../formFields';
import { ErrorLabel, FormLabel, FormValue, InactiveLabel, ListLabel, UnknownLabel } from '../../styledComponets/Labels';
import ModalPopup from '../../styledComponets/ModalPopup';
import { FormHeader, MainTitle } from '../../styledComponets/Titles';
import useRequest from '../../../utils/hooks/useRequest';
import { InactiveLabelContainer, LabelContainer } from '../../styledComponets/Containers';
import { checkTextFitsInComponent } from '../../../utils/fieldValidators'
import configContext from '../../../context/configContext/configContext';


function ViewUser(props) {
    let labelRef = useCallback(node => {
        if(node !== null){
            labelRef.current = node;
        }
    }, [])
    const { setGlobalMessage } = useGlobalMessenger();
    const { userInfo } = useContext(globalContext);
    const { configInfo } = useContext(configContext);
    const [userDetail, setUserDetail] = useState();
    const [apiResponse, setApiResponse] = useState({});
    const [statusUpdateApiResponse, setStatusUpdateApiResponse] = useState({});

    const { selectedUserId } = props;

    const getUserDetailApiReq = useRequest(getUserDetailApi);
    const resetUserPasswordApiReq = useRequest(resetUserPasswordApi);
    const updateUserStatusApiReq = useRequest(updateUserStatus);

    useEffect(() => {
        getUserDetailApiReq.request(selectedUserId, setApiResponse);
    }, []);

    useEffect(() => {
        setUserDetail(apiResponse?.data);
    }, [apiResponse]);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [apiPwdResetResponse, setApiPwdResetResponse] = useState({});

    const handleConfirmPasswordReset = () => {
        resetUserPasswordApiReq.request(selectedUserId, setApiPwdResetResponse);
    };

    useEffect(() => {
        if (apiPwdResetResponse?.data) {
            setGlobalMessage({ text: apiPwdResetResponse?.data });
            setConfirmOpen(false);
        } 
    }, [apiPwdResetResponse]);

    const handleStatusClick = () => {
        var payload = { "status": userDetail.status === "Active" ? "Inactive" : "Active" }
        updateUserStatusApiReq.request(userDetail.userId, payload, setStatusUpdateApiResponse)
    }

    useEffect(() => {
        if (statusUpdateApiResponse?.data) {
            setGlobalMessage({ text: "The user status is updated as " + statusUpdateApiResponse.data.status });
            setUserDetail({ ...userDetail, "status": statusUpdateApiResponse.data.status })
        }
    }, [statusUpdateApiResponse]);

    return (userDetail ? (
        <>
            <Grid container spacing={1} >
                <Grid item sm={12}>
                    <MainTitle>View User {userDetail.lastName}, {userDetail.firstName}</MainTitle>
                </Grid>
                <Grid item sm={12} sx={{ margin: "1em 0" }}>
                    <Stack direction='row' spacing={2}>
                        <div>
                            <Avatar sx={{ width: 150, height: 150, bgcolor: "#0062b2" }} variant="rounded" />
                        </div>
                        <div>
                            <Grid container spacing={.5}>
                                <Grid item sm={3}>
                                    <FormLabel>Email</FormLabel>
                                </Grid>
                                <Grid item sm={9}>
                                    <FormValue noWrap>{userDetail.email}</FormValue>
                                </Grid>
                                <Grid item sm={3}>
                                    <FormLabel>Username</FormLabel>
                                </Grid>
                                <Grid item sm={9}>
                                    <Stack direction='row'>
                                        <FormValue>{userDetail.userName}</FormValue>
                                        {userInfo.roles?.includes("manage_users") &&
                                            <StatusChip disabled={updateUserStatusApiReq.inprogress} onClick={handleStatusClick} status={userDetail.status === "Active"} label={userDetail.status ? userDetail.status : "Inactive"} />
                                        }
                                    </Stack>
                                </Grid>
                                {   configInfo.AllowExplicitAgentId && 
                                    <>
                                        <Grid item sm={3}>
                                            <FormLabel>Affiliate agent ID</FormLabel>
                                        </Grid>
                                        <Grid item sm={9}>
                                            {userDetail.affiliateAgentId ? <FormValue>{userDetail.affiliateAgentId}</FormValue> : <UnknownLabel>Unavailable</UnknownLabel>}
                                        </Grid>
                                    </>
                                }
                                <Grid item sm={3}>
                                    <FormLabel>User type</FormLabel>
                                </Grid>
                                <Grid item sm={9}>
                                    {userDetail.userType ? <FormValue>{userDetail.userType?.name}</FormValue> : <UnknownLabel>Unavailable</UnknownLabel>}
                                </Grid>
                                <Grid item sm={3}>
                                    <FormLabel>Phone number</FormLabel>
                                </Grid>
                                <Grid item sm={9}>
                                    {userDetail.phoneNumber ? <FormValue>{userDetail?.phoneNumber }</FormValue> : <UnknownLabel>Unavailable</UnknownLabel>}
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </Grid>
                {userDetail.affiliates ? (
                    <Grid item sm={12} md={12}>
                        <FormHeader>Associated Affiliate(s)</FormHeader>
                        <Grid container rowSpacing={1} columnSpacing={2}>
                            {userDetail.affiliates.map(affiliate => (
                                affiliate.active ? 
                                <Grid item key={affiliate.affiliateId} md={4} sm={6} title={affiliate.affiliateName}>
                                    <LabelContainer>
                                        <ListLabel ref={labelRef}>
                                            {checkTextFitsInComponent(affiliate.affiliateName, labelRef)}
                                        </ListLabel>
                                    </LabelContainer>
                                </Grid>
                            : 
                                <Grid item key={affiliate.affiliateId} md={4} sm={6} title={affiliate.affiliateName + ' (Inactive)'}>
                                    <InactiveLabelContainer>
                                        <InactiveLabel ref={labelRef}>
                                            {checkTextFitsInComponent(affiliate.affiliateName, labelRef)}
                                        </InactiveLabel>
                                    </InactiveLabelContainer>
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>) : (<></>)
                }
                <Grid item md={4} sm={5} sx={{ marginTop: '1em' }}>
                    {(userInfo.roles?.includes('manage_users') || userInfo.roles?.includes('reset_user_password')) && <Button text='Reset Password' onClick={(e) => setConfirmOpen(true)} />}
                    <ModalPopup open={confirmOpen} onClose={(e) => setConfirmOpen(false)} maxWidth="sm"
                        innerComponent={
                            <Grid container spacing={2}>
                                <Grid item sm={12} >
                                    <MainTitle>Are you sure to reset password?</MainTitle>
                                </Grid>
                                <Grid item sm={12} >
                                    <ErrorLabel>
                                        This will reset the password for {userDetail.lastName}, {userDetail.firstName} and send over to {userDetail.email}
                                    </ErrorLabel>
                                </Grid>
                                <Grid item sm={12} >
                                    <Stack direction='row' columnGap={2}>
                                        <Button text='No' variant="outlined" onClick={(e) => setConfirmOpen(false)} />
                                        {userInfo.roles?.includes("reset_user_password") &&
                                            <Button disabled={resetUserPasswordApiReq.inprogress} text='Yes - Reset Password' onClick={handleConfirmPasswordReset} />
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        } />
                </Grid>
                <Grid item md sm />
            </Grid>
        </>
    ) : (<><CircularProgress color="inherit" /></>)
    );
}

export default ViewUser;