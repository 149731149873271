import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

const useGlobalMessenger = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [message, setGlobalMessage] = useState({ text: '', variant:'success'});

    useEffect(() => {
        if (message&&message?.text) {
            let variant = message.variant ? message.variant : "success";
            enqueueSnackbar(message.text,
                {
                    variant: variant,
                    autoHideDuration: 3000,
                    anchorOrigin:{
                        horizontal:'right',
                        vertical:'top'
                    },
                });
        }
    }, [message]);
    return { setGlobalMessage };
};

export default useGlobalMessenger;