import { getAuthToken } from "./authHelper";

export const getApiOptions = () => {
    return {
        withCredentials: false,
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + getAuthToken()
        },
    };
};

export const errorFormat = (error) => {
    if (error?.response) {
        return {
            isError: true,
            error: {
                statusCode: error?.response?.status,
                errorMessage: error?.response?.statusText,
                errorRes: error?.response?.data,
            }
        };
    }
    return {
        isError: true,
    }
}