import React from 'react';
import { defaultConfigInfo } from '../../utils/configStateHelper';

export const getDefaultContext = () => ({
  configInfo: {
    ...defaultConfigInfo
  }
});

const setDefaultContext = {
  setConfigInfo: () => {/* Implemented in reducer */ },
  resetConfigInfo: () => {}
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });