import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const SectionContainer = styled(Paper)({
    padding: '2em',
    borderRadius: '.5em',
    margin: '1em 0',
});

export const LabelContainer = styled(Paper)({
    padding: "1em 1em 0em 1em",
    borderRadius: "0.5em",
    border: "solid 0.5px #003561",
    backgroundColor: "#fff"
});

export const InactiveLabelContainer = styled(Paper)({
    padding: "1em 1em 0em 1em",
    borderRadius: "0.5em",
    border: "solid 0.5px grey",
    backgroundColor: "#fff"
});