import React from 'react'
import { TextField } from '@mui/material';

const Input = React.forwardRef((props, ref) => {

    const { name, label, value, error = null, onChange, height = ".1em", ...other } = props;
    return (
        <TextField fullWidth
            variant="outlined"
            label={label}
            name={name}
            value={value}
            ref={ref}
            onChange={onChange}
            height="0.8em"
            {...other}
            {...(error && { error: true, helperText: error })}
        />
    )
})

export default Input;