/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import GlobalContext, {getDefaultContext} from './globalContext';
import globalReducer from './globalReducer';
import {
  SET_USER_INFO,
  SET_BUYFLOW_LAUNCH_INFO,
  INCREMENT_REQUEST_COUNT,
  DECREMENT_REQUEST_COUNT
} from './globalActionTypes';

const GlobalState = props => {
  const [globalContext, dispatch] = useReducer(globalReducer, getDefaultContext());
  const setUserInfo = userInfo => dispatch({ type: SET_USER_INFO, payload: userInfo });
  const setLaunchBuyflowInfo = buyflowLaunchInfo => dispatch({ type: SET_BUYFLOW_LAUNCH_INFO, payload: buyflowLaunchInfo });
  const incrementOpenRequestCount = () => dispatch({ type: INCREMENT_REQUEST_COUNT, payload: null });
  const decrementOpenRequestCount = () => dispatch({ type: DECREMENT_REQUEST_COUNT, payload: null });


  return (
    <GlobalContext.Provider
      value={{
        ...globalContext,
        setUserInfo,
        setLaunchBuyflowInfo,
        incrementOpenRequestCount,
        decrementOpenRequestCount
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
GlobalState.propTypes = {
  children: PropTypes.object,
};

export default GlobalState;
