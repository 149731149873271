import React from 'react';
import { defaultOrderReportInfo } from "../../utils/reportStateHelper";

export const getDefaultContext = () => ({
  coreOrderReportInfo: {
    ...defaultOrderReportInfo,
  }
});

const setDefaultContext = {
  setCoreOrderReportInfo: () => {/* Implemented in reducer */ },
  resetCoreOrderReportInfo: () => {}
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });