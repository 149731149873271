import React, { Fragment, useState } from 'react'
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "../formFields";

export default function SearchControl(props) {
    const { name, placeholderText, onSearch, onChange, onReset, isExpanded, canBeReset } = props;

    const [expanded, setExpanded] = useState(() => {
        return isExpanded ? isExpanded : false;
    });
    const [canReset, setCanReset] = useState(() => {
        return canBeReset ? canBeReset : false;
    });

    const [searchText, setSearchText] = useState('');
    const onSearchTextChange = (text) => {
        setSearchText(text);
        if (onChange) { onChange(text); }
    }

    const onSeachClick = (e) => {
        e.preventDefault();
        onSearch(searchText)
        setCanReset(true);
    }

    const onResetClick = (e) => {
        setSearchText('');
        setCanReset(false);
        if (onReset) { onReset(); }
    }

    const handleSearchFocusOut = () => {
        if (searchText?.length === 0) {
            setExpanded(false);
        }
    }

    return (
        <Fragment name={name}>
            <Form>
            <input type="submit" onClick={onSeachClick} style={{ visibility: "hidden", height: "0em", position: 'absolute', zIndex: '100' }} />
            {expanded ? (
                <TextField size="small" sx={{ backgroundColor: '#fff' }} variant="standard" disabled={canReset} onBlur={handleSearchFocusOut}
                    placeholder={placeholderText} onChange={(e) => onSearchTextChange(e.target.value)} value={searchText}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">
                            {canReset ? (
                                <IconButton onClick={onResetClick}>
                                    <Tooltip arrow title="Reset">
                                        <CloseIcon />
                                    </Tooltip>
                                </IconButton>) : (<>
                                    <IconButton onClick={onSeachClick} disabled={searchText?.length === 0} >
                                        <Tooltip arrow title="Search">
                                            <SearchIcon />
                                        </Tooltip>
                                    </IconButton>
                                </>)}

                        </InputAdornment>
                    }}>
                </TextField>) : (
                <IconButton sx={{ padding: "0 0.1em" }}
                    onClick={(e) => setExpanded(true)}                     >
                    <Tooltip arrow title="Search">
                        <SearchIcon />
                    </Tooltip>
                </IconButton>
            )}
        </Form>
        </Fragment>
    )
}