import axios from "axios";
import URLs from "../utils/urls";
import { getApiOptions, errorFormat } from "../utils/apiHelpers";
import { defaultUserInfo } from "../utils/globalStateHelper";
import { getContextInfo, setAuthToken } from "../utils/authHelper";

export async function loginApi(reqPayload, setResponse) {
  const ops = {
    method: 'post',
    url: URLs.LOGIN_USER,
    data: reqPayload,
    ...getApiOptions(),
  };
  try {
    setResponse({ isLoading: true });
    const response = await axios(ops);
    setAuthToken(response?.data?.jwtToken);
    const contextInfo = getContextInfo();
    setResponse({
      ...contextInfo.userInfo,
      nextAction: response?.data?.forceUserTo ? response.data.forceUserTo : null
    },
    {
      ...contextInfo.configInfo
    });
  } catch (error) {
    setResponse({
      ...defaultUserInfo,
      ...errorFormat(error)
    });
  }
}

export async function renewTokenApi(setResponse) {
  const ops = {
    method: 'post',
    url: URLs.RENEW_TOKEN,
    ...getApiOptions(),
  };
  try {
    setResponse({ isLoading: true });
    const response = await axios(ops);
    setAuthToken(response?.data?.jwtToken);
    const contextInfo = getContextInfo();
    setResponse({
      ...contextInfo.userInfo,
      nextAction: response?.data?.forceUserTo ? response.data.forceUserTo : null
    },
    {
      ...contextInfo.configInfo
    });
  } catch (error) {
    setResponse({
      ...defaultUserInfo,
      ...errorFormat(error)
    });
  }
}

