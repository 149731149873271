/* eslint-disable no-undef */
import React, { useContext, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';

import { ROUTES } from "../../utils/consts";
import { useEffect } from "react";
import { getContextInfo, isLoggedIn } from "../../utils/authHelper";
import { defaultUserInfo } from "../../utils/globalStateHelper";
import { defaultConfigInfo } from "../../utils/configStateHelper";
import GlobalContext from "../../context/globalContext/globalContext";
import { MenuItem, Tooltip } from '@mui/material';
import userManagementContext from "../../context/userManagementContext/userManagementContext";
import affiliateManagementContext from "../../context/affiliateManagementContext/affiliateManagementContext";
import affiliateUserReportContext from "../../context/affiliateUserReportContext/affiliateUserReportContext";
import coreOrderReportContext from "../../context/coreOrderReportContext/coreOrderReportContext";
import mobileOrderReportContext from "../../context/mobileOrderReportContext/mobileOrderReportContext";
import ConfigContext from '../../context/configContext/configContext';

const drawerWidth = 240;

const mixin = (theme) => ({
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const SideBarMenuWrapper = styled(MuiDrawer, {})(
    ({ theme }) => ({
        width: drawerWidth,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        paddingTop: '1em',
        ...({
            ...mixin(theme),
            '& .MuiDrawer-paper': {
                ...mixin(theme),
                overflow: 'visible',
                display: 'flex',
                justifyContent: 'space-between',
            },
            '&.side-bar-expanded .MuiDrawer-paper': {
                width: 300
            }
        }),
    }),
);

const ReportsMenu = () => {
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(GlobalContext);
    const onMenuItemClick = (event, itemUrl) => {
        event.stopPropagation();
        navigate(ROUTES.REPORTS_ROUTE + '/' + itemUrl);
    }

    const menuItems = <>
        {userInfo.roles?.includes('rpt_affiliate_users') &&
            <MenuItem style={{ paddingBottom: 15 }} onClick={(event) => onMenuItemClick(event, ROUTES.AFFILIATE_USER_REPORT_ROUTE)}>Affiliate Users Report</MenuItem>}
        {userInfo.roles?.includes('rpt_core_orders') &&
            <MenuItem style={{ paddingBottom: 15 }} onClick={(event) => onMenuItemClick(event, ROUTES.CORE_ORDER_REPORT_ROUTE)}>Core Orders Report</MenuItem>}
        {userInfo.roles?.includes('rpt_mobile_orders') &&
            <MenuItem style={{ paddingBottom: 15 }} onClick={(event) => onMenuItemClick(event, ROUTES.MOBILE_ORDER_REPORT_ROUTE)}>Mobile Orders Report</MenuItem>}
    </>
    return <div className="custom-menu" >
        {menuItems}</div>
}

const SideBarMenu = (props) => {
    const [activeItem, setActiveItem] = useState(props.selectedOption || "Home");
    const { userInfo, setUserInfo } = useContext(GlobalContext);
    const { configInfo, setConfigInfo } = useContext(ConfigContext);
    const navigate = useNavigate();
    const { resetUserManagementInfo } = useContext(userManagementContext);
    const { resetAffiliateManagementInfo } = useContext(affiliateManagementContext);
    const { resetAffiliateReportInfo } = useContext(affiliateUserReportContext);
    const { resetCoreOrderReportInfo } = useContext(coreOrderReportContext);
    const { resetMobileOrderReportInfo } = useContext(mobileOrderReportContext)
    const { resetConfigInfo } = useContext(ConfigContext);
    const [isExpanded, setIsExpanded] = useState(false);

    // To keep user logged for hard refresh
    useEffect(() => {
        if (!isLoggedIn()) {
            clearContexts();
            navigate(ROUTES.LOGIN_ROUTE);
        } else if (!userInfo.userName) {
            var contextInfo = getContextInfo();

            setUserInfo({
                ...defaultUserInfo,
                ...contextInfo?.userInfo,
            });
            setConfigInfo({
                ...defaultConfigInfo,
                ...contextInfo?.configInfo
            })
        }
    }, [userInfo.userName]);

    const clearContexts = () => {
        setUserInfo({ ...defaultUserInfo });
        resetAffiliateReportInfo();
        resetAffiliateManagementInfo();
        resetCoreOrderReportInfo();
        resetMobileOrderReportInfo();
        resetUserManagementInfo();
        resetConfigInfo();
    }

    const onReportMenuClick = () => {
        setActiveItem('Reports');
        if (userInfo.roles?.includes('rpt_affiliate_users')) {
            navigate(ROUTES.REPORTS_ROUTE + '/' + ROUTES.AFFILIATE_USER_REPORT_ROUTE);
        } else if (userInfo.roles?.includes('rpt_core_orders')) {
            navigate(ROUTES.REPORTS_ROUTE + '/' + ROUTES.CORE_ORDER_REPORT_ROUTE);
        } else if (userInfo.roles?.includes('rpt_mobile_orders')) {
            navigate(ROUTES.REPORTS_ROUTE + '/' + ROUTES.MOBILE_ORDER_REPORT_ROUTE);
        } 
    }


    return (
        <SideBarMenuWrapper variant="permanent" className={isExpanded ? 'side-bar-expanded' : ''}>
            <List sx={{ paddingTop: '4em' }}>
                {/*TODO: Refactor List item as separate component*/}
                {userInfo.roles?.includes("portal_user") &&
                    <Tooltip title="Home" placement='right'>
                        <ListItem key={'home'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton selected={(activeItem === "Home")}
                                onClick={() => navigate(ROUTES.HOME_ROUTE)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <HomeOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                    {isExpanded && <span>Home</span>}
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                }
                {userInfo.roles?.includes("search_location") &&
                    <Tooltip title="Location ID Lookup" placement='right'>
                        <ListItem key={'addressSearch'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton selected={(activeItem === "AddressSearch")}
                                onClick={() => navigate(ROUTES.ADDRESS_SEARCH_ROUTE)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        justifyContent: 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <LocationSearchingOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                    {isExpanded && <span>Account Search</span>}
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                }
                {userInfo.roles?.includes("search_accounts") &&
                    <Tooltip title="Account Number Lookup" placement='right'>
                        <ListItem key={'accountSearch'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton selected={(activeItem === "AccountSearch")}
                                onClick={() => navigate(ROUTES.ACCOUNT_NUMBER_SEARCH_ROUTE)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <PersonSearchOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                    {isExpanded && <span>Account Search</span>}
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                }
                {userInfo.roles?.includes("view_users") &&
                    <Tooltip title="Manage Users" placement='right'>
                        <ListItem key={'users'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton selected={(activeItem === "Users")}
                                onClick={() => navigate(ROUTES.USERS_ROUTE)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <PeopleOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                    {isExpanded && <span>Users</span>}
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                }
                {userInfo.roles?.includes("view_affiliates") &&
                    <Tooltip title="Manage Affiliates" placement='right'>
                        <ListItem key={'viewAffiliates'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton selected={(activeItem === "Affiliates")}
                                onClick={() => navigate(ROUTES.AFFILIATES_ROUTE)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <AccountTreeOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                    {isExpanded && <span>Affiliates</span>}
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                }
                {userInfo.roles?.find(y => y.indexOf('rpt_') === 0) &&
                    <ListItem key={'viewReports'} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton selected={(activeItem === "Reports")}
                            onClick={onReportMenuClick}
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 2.5,
                                position: 'relative'
                            }}
                            id="report-side-bar-menu"
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AssessmentOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                {isExpanded && <span>Reports</span>}
                            </ListItemIcon>
                            {(!isExpanded || activeItem !== 'Reports') && <ReportsMenu roles={userInfo.roles} floatingMenu={true} />}
                        </ListItemButton>
                        {isExpanded && activeItem === 'Reports' && <ReportsMenu roles={userInfo.roles} floatingMenu={false} />}
                    </ListItem>
                }
                {userInfo.roles?.includes("portal_user") &&
                    <Tooltip title="Account Settings" placement='right'>
                        <ListItem key={'settings'} disablePadding sx={{ display: 'block' }} selected={(activeItem === "Settings")}>
                            <ListItemButton
                                onClick={() => navigate(ROUTES.SETTINGS_ROUTE)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SettingsOutlinedIcon sx={{ width: '40px', color: '#003561' }} />
                                    {isExpanded && <span>Settings</span>}
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                }
            </List>
            {/* <List sx={{ paddingTop: '4em' }}>
                <ListItem key={'expandCollapse'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {isExpanded ? <><ArrowBackIcon sx={{ width: '40px', color: '#003561' }} />{isExpanded && <span>Collapse Back</span>}</> :
                                <ArrowForwardIcon sx={{ width: '40px', color: '#003561' }} />
                            }
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List> */}
        </SideBarMenuWrapper>
    );
}

export default SideBarMenu;