/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import { RESET_USER_MANAGEMENT_CONTEXT, SET_USER_SEARCH_CRITERIA } from './userManagementActionTypes';
import UserManagementContext, {getDefaultContext} from './userManagementContext';
import userManagementReducer from "./userManagementReducer";

const UserManagementState = props => {
  const [userManagementContext, dispatch] = useReducer(userManagementReducer, getDefaultContext());
  const setUserManagementInfo = searchCriteria => dispatch({ type: SET_USER_SEARCH_CRITERIA, payload: searchCriteria });
  const resetUserManagementInfo = () => dispatch({ type: RESET_USER_MANAGEMENT_CONTEXT, payload: null });

  return (
    <UserManagementContext.Provider
      value={{
        ...userManagementContext,
        setUserManagementInfo,
        resetUserManagementInfo
      }}
    >
      {props.children}
    </UserManagementContext.Provider>
  );
};
UserManagementState.propTypes = {
  children: PropTypes.object,
};

export default UserManagementState;
