import { getDefaultContext } from './globalContext';
import {
  SET_USER_INFO,
  SET_BUYFLOW_LAUNCH_INFO,
  RESET,
  INCREMENT_REQUEST_COUNT,
  DECREMENT_REQUEST_COUNT
} from './globalActionTypes';

const globalReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...payload
        }
      };
    case SET_BUYFLOW_LAUNCH_INFO:
      return {
        ...state,
        launchBuyflowInfo: {
          ...state.launchBuyflowInfo,
          ...payload
        }
      };
    case RESET:
      return { ...state, ...getDefaultContext() };
    case INCREMENT_REQUEST_COUNT:
      return { ...state, openRequests: state.openRequests + 1 }
    case DECREMENT_REQUEST_COUNT:
      let newRequestsCount = state.openRequests - 1;
      if (newRequestsCount < 0) {
        newRequestsCount = 0;
      }
      return { ...state, openRequests: newRequestsCount };
    default:
      return state;
  }
};

export { globalReducer as default };
