import axios from "axios";
import URLs from "../utils/urls";
import { getApiOptions } from "../utils/apiHelpers";

export async function getAccountNumbersByEmail(emailAddress, setResponse) {
    const endpointUrl = `${URLs.ACCOUNT_NUMBER_SEARCH}?emailAddress=${emailAddress}`;
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions()
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}