import React, { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { getUserMessagesApi } from "../../api/messageApi";
import useRequest from "../../utils/hooks/useRequest";
import globalContext from "../../context/globalContext/globalContext";

function UserMessages() {
    const { userInfo } = useContext(globalContext);

    const [apiResponse, setApiResponse] = useState({});
    const [userMessages, setUserMessages] = useState([]);

    const getUserMessagesApiReq = useRequest(getUserMessagesApi);

    useEffect(() => {
        getUserMessagesApiReq.request(setApiResponse);
    }, [userInfo.userName]);

    useEffect(() => {
        console.log(apiResponse?.data);
        setUserMessages(apiResponse?.data)
    }, [apiResponse]);

    function translateMessageSeverity(severity) {
        switch (severity?.toLowerCase()) {
            case "critical":
                return "error";
            case "warning":
                return "warning";           
            default:
                return "info";
        }       
    }

    return (<>{userMessages &&
        <Grid>
            {userMessages?.map(msg => (
                <Alert severity={translateMessageSeverity(msg.severity)} key={msg.id}>
                    <AlertTitle>{msg.title}</AlertTitle>
                    {msg.details}
                </Alert>
            ))}
        </Grid>
    }
    </>);
}
export default UserMessages;