import { Breadcrumbs, Grid, Link } from "@mui/material";
import React, { useContext } from "react";
import { MainTitle } from "../components/styledComponets/Titles";
import SideBarMenu from "../components/header/sideBarMenu";
import { Stack } from "@mui/system";
import ChangePassword from "../components/accounts/changePassword/ChangePassword";
import SecurityQuestion from "../components/accounts/securityQuestion/SecurityQuestion";
import globalContext from "../context/globalContext/globalContext";
import { FormLabel, FormValue, UnknownLabel} from "../components/styledComponets/Labels";

function Settings() {
    const { userInfo } = useContext(globalContext);
    
    return (
        <>
            <SideBarMenu selectedOption="Settings" />
            <Breadcrumbs separator="|" sx={{ marginBottom: '1em' }}>
                <Link href="/home" underline="hover" sx={{ color: "#003561", fontSize: '14px', padding: '0' }}>Home</Link>
                <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Settings</Link>
            </Breadcrumbs>
            <Grid container spacing={1}>
                <Grid item sm={12} >
                    <MainTitle>Profile Summary</MainTitle>
                    <hr />
                </Grid>
                <Grid item sm={12}>
                    <FormLabel>Username</FormLabel>
                    <FormValue>{userInfo.userName}</FormValue>
                </Grid>
                <Grid item sm={12}>
                    <Stack direction='row' spacing={5}>
                        <Stack direction='column'>
                            <FormLabel>First name</FormLabel>
                            <FormValue>{userInfo.firstName}</FormValue>
                        </Stack>
                        <Stack direction='column'>
                            <FormLabel>Last name</FormLabel>
                            <FormValue>{userInfo.lastName}</FormValue>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item sm={12}>
                    <Stack direction='column'>
                        <FormLabel>Email</FormLabel>
                        <FormValue>{userInfo.email}</FormValue>
                    </Stack>
                </Grid>
                <Grid item sm={12}>
                    <FormLabel>Phone number</FormLabel>
                    {userInfo?.phoneNumber && userInfo.phoneNumber != "NA" ? <FormValue>{userInfo.phoneNumber}</FormValue> : <UnknownLabel>Unavailable</UnknownLabel>}
                </Grid>
            </Grid>
            <br/>
            <ChangePassword />
            <br />
            <SecurityQuestion />
            <br />
        </>
    );
}

export default Settings;