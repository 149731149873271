import { getTextWidth } from 'get-text-width'

export const isUsernameContainsSpecialChars = (str) => {
    // eslint-disable-next-line 
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
    return specialChars.test(str);
};

export const containsSpecialChars = (str) => {
    // eslint-disable-next-line 
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?~]/;
    return specialChars.test(str);
};

export const isValidEmailAddress = (input) => {
        const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
        if(!input || input.length === 0) {
            return false;
        }
    return emailCheckRegex.test(input);
};

export const isValidPhoneNumber = (input) => {
    const emailCheckRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    if (!input || input.length === 0) {
        return false;
    }
    return emailCheckRegex.test(input);
};

export const checkTextFitsInComponent = (providedText, labelRef) => {
    let displayName = "";

    if(labelRef.current){
        var textWidth = getTextWidth(providedText)
        var maxWidth = (0.8 * labelRef.current.clientWidth);
        if(textWidth < maxWidth){
            displayName = providedText;
        } else{
            displayName = providedText;

            while(getTextWidth(displayName) > maxWidth){
                displayName = displayName.slice(0, -1);
            }

            displayName += "..."
        }
    } else{
        if(providedText.length > 20){
            displayName = providedText?.substring(0, 20) + "...";
        } else{
            displayName = providedText;
        }
    }
    return displayName;
}