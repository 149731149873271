import React, { useContext } from "react";
import { Box, Breadcrumbs, Grid, Link } from "@mui/material";
import SideBarMenu from "../components/header/sideBarMenu";
import { MainTitle } from "../components/styledComponets/Titles";
import LaunchBuyflow from "../components/buyflow/LaunchBuyflow";
import GlobalContext from "../context/globalContext/globalContext";
import UserMessages from '../components/header/userMessages';

function Home() {
    const { userInfo } = useContext(GlobalContext);

    return (
        <>
            <SideBarMenu selectedOption="Home" />
            {userInfo.userName && <UserMessages />}
            <Breadcrumbs separator="|" sx={{ marginBottom: '1em' }}>
                <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Home</Link>
            </Breadcrumbs>
            <Grid container spacing={1}>
                <Grid item sm={12} >
                    <MainTitle>Welcome! {`${userInfo.firstName} ${userInfo.lastName}`}</MainTitle>
                </Grid>
                {userInfo.roles?.includes("launch_buyflow") &&
                    <Grid item sm={12} >
                        <LaunchBuyflow />
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default Home;