import React from 'react'
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox, Typography } from '@mui/material';

export default function Checkbox(props) {
    const { name, value, label, onChange, checked, disabled, color } = props;
    // const convertToDefEventPara = (name, value) => ({
    //     target: {
    //         name, value
    //     }
    // })

    return (
        <FormControl>
            <FormControlLabel
                control={<MuiCheckbox
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={e => onChange(e)}
                    disabled={disabled}
                />}
                label={
                    <Typography sx={{
                        fontWeight: checked ? "bold" : "normal",
                        color: color ? color : "#003561"
                    }}>{label}</Typography>
                }
            />
        </FormControl>
    )
}
