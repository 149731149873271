export const ROUTES = {
  LOGIN_ROUTE: '/login',
  HOME_ROUTE: '/home',
  USERS_ROUTE: '/users',
  AFFILIATES_ROUTE: '/affiliates',
  ACCOUNT_NUMBER_SEARCH_ROUTE: '/account-search',
  ADDRESS_SEARCH_ROUTE:'/address-search',
  REPORTS_ROUTE:'/reports',
  AFFILIATE_USER_REPORT_ROUTE: 'affliate-user',
  CORE_ORDER_REPORT_ROUTE: 'core-orders',
  MOBILE_ORDER_REPORT_ROUTE:'mobile-orders',
  SETTINGS_ROUTE: '/settings',
  FORGETPASSWORD_ROUTE: '/forgotpassword',
  CHANGE_PASSWORD_ROUTE: '/changepassword',
  SECURITY_QUESTION_ROUTE: '/securityquestion',
  DEFAULT_ROUTE: '/',
};
export const HTTP_VERB = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const SESSION_POPOVER = {
  TIMEOUT_IN_MIN: 10,
  SESSION_TIMEOUT_KEY: 'sessionTimeoutInSec'
};

