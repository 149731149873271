import { getDefaultContext } from './affiliateUserReportContext';
import { RESET_AFFILIATE_USER_REPORT_INFO, SET_AFFILIATE_USER_REPORT_INFO } from './affiliateUserReportActionTypes';


const affiliateUserReportReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_AFFILIATE_USER_REPORT_INFO:
      return {
        ...state,
        affiliateReportInfo: {
          ...state.affiliateReportInfo,
          ...payload
        }
      };
    case RESET_AFFILIATE_USER_REPORT_INFO:
      return{
        ...getDefaultContext()
      }
    default:
      return state;
  }
};

export { affiliateUserReportReducer as default };
