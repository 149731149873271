export const resetError = {
    isError: false,
    error: {
        statusCode: '',
        errorMessage: '',
        errorRes: {},
    },
    isLoading: false,
};

export const defaultUserInfo = {
    isError: false,
    isLoading: false,
    error: {
        statusCode: '',
        errorMessage: '',
        errorRes: {},
    },
    email: '',
    firstName: '',
    lastName: '',
    type: '',
    userId: '',
    userName: '',
    nextAction: '',
    roles: [],
    useUserNameAsAgentId: false
};

export const defaultBuyflowLaunchInfo = {
    selectedAffiliate: null,
    selectedStore: null,
    totalAffiliatesCount:0
};