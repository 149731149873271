/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import ConfigContext, {getDefaultContext} from './configContext';
import configReducer from './configReducer';
import {
  SET_CONFIG_INFO,
  RESET
} from './configActionTypes';

const ConfigState = props => {
  const [configContext, dispatch] = useReducer(configReducer, getDefaultContext());
  const setConfigInfo = configInfo => dispatch({ type: SET_CONFIG_INFO, payload: configInfo });
  const resetConfigInfo = () => dispatch({type: RESET})


  return (
    <ConfigContext.Provider
      value={{
        ...configContext,
        setConfigInfo,
        resetConfigInfo
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};
ConfigState.propTypes = {
  children: PropTypes.object,
};

export default ConfigState;
