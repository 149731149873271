import React from 'react';
import { defaultAffiliateReportInfo } from "../../utils/reportStateHelper";

export const getDefaultContext = () => ({
  affiliateReportInfo: {
    ...defaultAffiliateReportInfo,
  }
});

const setDefaultContext = {
  setAffiliateReportInfo: () => {/* Implemented in reducer */ },
  resetAffiliateReportInfo: () => {}
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });