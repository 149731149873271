import { getDefaultContext } from './mobileOrderReportContext';
import { SET_MOBILE_ORDER_REPORT_INFO } from './mobileOrderReportActionTypes';
import { RESET_MOBILE_ORDER_CONTEXT } from './mobileOrderReportActionTypes';


const mobileOrderReportReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_MOBILE_ORDER_REPORT_INFO:
      return {
        ...state,
        mobileOrderReportInfo: {
          ...state.mobileOrderReportInfo,
          ...payload
        }
      };
    case RESET_MOBILE_ORDER_CONTEXT:
      return{
        ...getDefaultContext()
      }
    default:
      return state;
  }
};

export { mobileOrderReportReducer as default };
