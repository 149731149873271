import styled from "@emotion/styled";
import { Typography, FormLabel as MuiFormLabel } from "@mui/material";

export const FormLabel = styled(MuiFormLabel)({
    fontSize: '16px',
    fontWeight: "normal",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003561',
    height: '30px',
    padding: '.1em 0'
});

export const FormValue = styled(Typography)({
    fontFamily: 'SpectrumSans',
    fontSize: '16px',
    fontWeight: "bolder",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003561',
    height: '30px',
    padding: '.1em 0'
});

export const ListLabel = styled(Typography)({
    fontFamily: 'SpectrumSans',
    fontSize: '14px',
    fontWeight: "bolder",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003561',
    height: '30px',
    padding: '.1em 0'
});

export const InactiveLabel = styled(Typography)({
    fontFamily: 'SpectrumSans',
    fontSize: '14px',
    fontWeight: "bolder",
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'grey',
    height: '30px',
    padding: '.1em 0'
});

export const SectionLabel = styled(MuiFormLabel)({
    fontSize: '22px',
    fontWeight: "bold",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#005a84',
    height: '30px',
    padding: '0'
});


export const UnknownLabel = styled(Typography)({
    fontSize: '14px',
    fontWeight: "lighter",
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'grey',
    height: '30px',
    padding: '.1em 0'
});

export const ErrorLabel = styled(Typography)({
    flexGrow: '0',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0',
    color: '#d6312b'
});

 export const SuccessLabel = styled(Typography)({
    flexGrow: '0',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0',
    color: 'green'
});


export const StepperLabel = styled(Typography)({
    flexGrow: '0',
    fontSize: '18px',
    fontWeight: '500', 
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color:'#003561'
});

export const LinkLabel = styled(Typography)({
    fontFamily: 'SpectrumSans',
    fontSize: '12px',
    fontWeight: "normal",
    fontStretch: 'normal',
    fontStyle: 'normal',
    textDecoration:'underline',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',verticalAlign:'center',
    color: '#003561',
    height: '30px',
    padding: '.1em 0',
    cursor:'pointer'
});