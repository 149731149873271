import jwtDecode from "jwt-decode";

export const setAuthToken = (jwtToken) => localStorage.setItem("userAuthToken", jwtToken);

export const getAuthToken = () => localStorage.getItem("userAuthToken");

export const clearAuthToken = () => localStorage.clear();

export const isLoggedIn = () => !!localStorage.getItem("userAuthToken");

export const getContextInfo = () => {
    const authToken = getAuthToken();
    const tokenInfo = jwtDecode(authToken);
    return { userInfo: {...tokenInfo?.userInfo, roles: tokenInfo?.roles, expiery:tokenInfo?.exp }, configInfo: {...tokenInfo?.configInfo}};
};