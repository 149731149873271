import React from 'react'
import InputMask from '@mona-health/react-input-mask';
import Input from './Input';

export default function PhoneNumber(props) {

    const { name, label, value, error = null, onChange, ...other } = props;

    return (
        <InputMask
            mask="(999) 999-9999"
            onChange={onChange}>
            <Input name={name} error={error} />
        </InputMask>
    )
}