/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import AffiliateManagementContext, {getDefaultContext} from './affiliateManagementContext';
import affiliateManagementReducer from './affiliateManagementReducer';
import { RESET_AFFILIATE_SEARCH_CRITERIA, SET_AFFILIATE_SEARCH_CRITERIA } from './affiliateManagementActionTypes';


const AffiliateManagementState = props => {
  const [affiliateManagementContext, dispatch] = useReducer(affiliateManagementReducer, getDefaultContext());
  const setAffiliateManagementInfo = searchCriteria => dispatch({ type: SET_AFFILIATE_SEARCH_CRITERIA, payload: searchCriteria });
  const resetAffiliateManagementInfo = () => dispatch({ type: RESET_AFFILIATE_SEARCH_CRITERIA, payload: null });

  return (
    <AffiliateManagementContext.Provider
      value={{
        ...affiliateManagementContext,
        setAffiliateManagementInfo,
        resetAffiliateManagementInfo
      }}
    >
      {props.children}
    </AffiliateManagementContext.Provider>
  );
};
AffiliateManagementState.propTypes = {
  children: PropTypes.object,
};

export default AffiliateManagementState;
