import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


export default function ModalPopup({ open, maxWidth = "lg", onClose, innerComponent, enableBackDropClose = true }) {


    const handleClose = () => { onClose(); };


    return (
        <Dialog maxWidth={maxWidth}
            open={open}
            onClose={enableBackDropClose ? onClose : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                {enableBackDropClose &&
                    <Tooltip title="Close" placement='left' arrow>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                padding: '1em',
                                "&:hover": { backgroundColor: "transparent" }
                            }}>
                            <CloseIcon sx={{ fontSize: '1.4em' }} />
                        </IconButton>
                    </Tooltip>
                }
            </DialogTitle>
            <DialogContent sx={{ marginLeft: ".5em" }}>
                {innerComponent}
            </DialogContent>
            <br />
        </Dialog>
    );
}