import React from 'react'
import { FormControl, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material'

export default function Select(props) {
    const { name, label, value, error = null, onChange, options } = props;

    return (
        <FormControl variant="outlined"
            {...(error && { error: true })} fullWidth={true}>
            <MuiSelect
                name={name}
                value={value}
                fullWidth={true}
                onChange={onChange}>
                <MenuItem value="0">None</MenuItem>
                {
                    options?.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}