import AffiliateManagementState from "./affiliateManagementContext/affiliateManagementState";
import AffiliateUserReportState from "./affiliateUserReportContext/affiliateUserReportState";
import ConfigState from "./configContext/configState";
import CoreOrderReportState from "./coreOrderReportContext/coreOrderReportState";
import GlobalState from "./globalContext/GlobalState";
import MobileOrderReportState from "./mobileOrderReportContext/mobileOrderReportState";
import UserManagementState from "./userManagementContext/userManagementState";

export default function DataContexts(props){
    return (
        <GlobalState>
            <ConfigState>
                <AffiliateManagementState>
                    <UserManagementState>
                        <AffiliateUserReportState>
                            <CoreOrderReportState>
                                <MobileOrderReportState>
                                    {props.children}
                                </MobileOrderReportState>
                            </CoreOrderReportState>
                        </AffiliateUserReportState>
                    </UserManagementState>
                </AffiliateManagementState>
            </ConfigState>
        </GlobalState>
    );
}