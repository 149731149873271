import { RESET_USER_MANAGEMENT_CONTEXT, SET_USER_SEARCH_CRITERIA } from './userManagementActionTypes';
import { getDefaultContext } from './userManagementContext';


const userManagementReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_USER_SEARCH_CRITERIA:
      return {
        ...state,
        userManagementInfo: {
          ...state.userManagementInfo,
          ...payload
        }
      };
    case RESET_USER_MANAGEMENT_CONTEXT:
      return {
        ...getDefaultContext()
      }
    default:
      return state;
  }
};

export { userManagementReducer as default };
