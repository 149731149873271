import common from "./scripts/common";

const {
  baseURL,
  env
} = global.DEFAULT_CONFIG || common.config;
export {
  baseURL,
  env
};
