export const defaultAffiliateReportInfo = {
    selectedAffiliates: [],
    searchCriteria: "",
    reportHasBeenGenerated: false
};

export const defaultOrderReportInfo = {
    selectedAffiliates: [],
    searchCriteria: "",
    reportData: {},
    reportHasBeenGenerated: false,
    fromDate: {},
    toDate: {}
}