import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls"; 

export async function getBuyflowUrlApi(affiliateId,storeId, setResponse) {
    const endpointUrl = storeId ? `${URLs.BUYFLOW}/${affiliateId}/${storeId}` : `${URLs.BUYFLOW}/${affiliateId}`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions()
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}