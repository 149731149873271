import React, {useState} from 'react'
import Button from '../formFields/Button';

export default function DeselectButton({handleClick, disabledState, selectedItemCount}){
    function deselectAll(){
        handleClick([]);
    }

    return (
        <Button size="small" variant="text" onClick={deselectAll}
            style={{maxWidth:'15%', fontSize:'12px', marginLeft: '0px', height: '100%', whiteSpace: "nowrap", display: 'inline-block'}}
            disabled={disabledState}
            text={"Deselect all (" + selectedItemCount + ")"}/>
    )
}