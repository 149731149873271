import React from 'react';
import { defaultUserInfo, defaultBuyflowLaunchInfo } from "../../utils/globalStateHelper";

export const getDefaultContext = () => ({
  userInfo: {
    ...defaultUserInfo,
  },
  launchBuyflowInfo: {
    ...defaultBuyflowLaunchInfo
  },
  openRequests: 0
});

const setDefaultContext = {
  setUserInfo: () => {/* Implemented in reducer */ },
  setLaunchBuyflowInfo: () => {/* Implemented in reducer */ }, 
  incrementOpenRequestCount: () => {/* Implemented in reducer */ },
  decrementOpenRequestCount: () => {/* Implemented in reducer */ },
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });