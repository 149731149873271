import React from 'react'
import { RadioGroup as MuiRadioGroup, FormControlLabel, Radio, Grid, FormGroup, Typography } from '@mui/material';

export default function RadioGroup(props) {

    const { name, label, value, onChange, items, itemPadding, allowDisplayInactiveAsDisabled, ...others } = props;

    return (
        <FormGroup>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange} {...others}>
                {
                    items?.map(
                        item => {
                            var color = (!item.isActive && allowDisplayInactiveAsDisabled) ? "#6e6e6e" : "#003561";
                            return (
                                <Grid key={item.id} item sm={6} md={6} lg={4}>
                                    <FormControlLabel key={item.id} value={item.id} control={<Radio checked={value === item.id} 
                                        sx={{
                                            '&.Mui-checked': {
                                                color: color
                                            }
                                        }}/>}
                                        label={<Typography sx={{
                                            fontWeight: value === item.id ? "bold" : "normal",
                                            color: color
                                        }}>{item.title}</Typography>} />
                                </Grid>
                            )
                        }
                    )
                }
            </MuiRadioGroup>
        </FormGroup>
    )
}