import React, { useEffect, useState, useRef } from 'react';
import { Box, Divider, Drawer, FormGroup, Grid, IconButton, Menu, MenuItem, Stack, TablePagination, Tooltip } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow} from '@mui/material';
import { MainTitle, ReportMessage } from '../styledComponets/Titles';
import Button from '../formFields/Button';
import { FormValue, ListLabel, UnknownLabel } from '../styledComponets/Labels';
import useRequest from '../../utils/hooks/useRequest';
import { getUserAffiliatesReportApi, downloadUserAffiliatesReportApi } from '../../api/reports';

import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { getUserAffiliatesApi } from "../../api/affiliateStoreApi";

import Checkbox from '../formFields/CheckBox';
import SearchControl from '../formFields/SearchControl';
import DeselectButton from '../formFields/DeselectButton';
import { useContext } from 'react';
import affiliateUserReportContext from '../../context/affiliateUserReportContext/affiliateUserReportContext';
import { MoreHoriz } from '@mui/icons-material';

function AffiliateUserReport(props) {

    const affiliatePerPageLimit = 14;

    const {affiliateReportInfo, setAffiliateReportInfo} = useContext(affiliateUserReportContext);

    const [daysSinceLogon, setDaysSinceLogon] = useState(0);

    const [userAffiliateApiResp, setUserAffiliateApiResp] = useState({});
    const [affiliateSearchCriteria, setAffiliateSearchCriteria] = useState(() => {
        const saved = affiliateReportInfo.searchCriteria;
        return saved.length >= 1 ? saved : "";
    });
    const [affiliates, setAffiliates] = useState();
    const [affiliatePage, setAffiliatePage] = useState(0);
    const [totalAffiliatesCount, setTotalAffiliatesCount] = useState(0);
    const [selectedAffiliates, setSelectedAffiliates] = useState(() => {
        const saved = affiliateReportInfo.selectedAffiliates;
        return saved.length >= 1 ? saved : [];
    });

    const [reportApiResponse, setReportApiResponse] = useState({});

    const [reportRecords, setReportRecords] = useState([]);
    const [currentAffilaiteId, setCurrentAffilaiteId] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMorePages, setHasMorePages] = useState(false);
    const [page, setPage] = useState(0);
    const [pageJustLoaded, setPageJustLoaded] = useState(true);
    const pageProcessing = useRef();

    const getUserAffiliatesReportApiReq = useRequest(getUserAffiliatesReportApi);
    const downloadUserAffiliatesReportApiReq = useRequest(downloadUserAffiliatesReportApi);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const getUserAffiliatesApiReq = useRequest(getUserAffiliatesApi);

    const loadAffiliates = () => {
        if (!getUserAffiliatesApiReq.inprogress) {
            getUserAffiliatesApiReq.request({ page: (parseInt(affiliatePage, 0) + 1), limit: affiliatePerPageLimit, affiliateName: affiliateSearchCriteria, includeInactive: true }, setUserAffiliateApiResp);
        }
    }

    useEffect(() => {
        loadAffiliates();
    }, [affiliatePage, affiliateSearchCriteria]);

    useEffect(() => {
        if (userAffiliateApiResp?.data) {
            setAffiliates(userAffiliateApiResp?.data);
            setTotalAffiliatesCount(userAffiliateApiResp?.pageInfo?.totalRecords)
            if (userAffiliateApiResp?.pageInfo?.totalRecords === 1 && affiliateSearchCriteria?.length === 0) {
                //setSelectedAffiliate(userAffiliateApiResp?.data[0])
            }
        } else {
            setAffiliates([]);
            setTotalAffiliatesCount(0)
        }
    }, [userAffiliateApiResp]);


    const generateFirstPageOfReport = () => {
        setCurrentPage(0);
        pageProcessing.current = true;
        setCurrentAffilaiteId(0);
        loadUserReportData(1);
        setPageJustLoaded(false);
        setAffiliateReportInfo({
            ...affiliateReportInfo,
            selectedAffiliates: selectedAffiliates,
            reportHasBeenGenerated: true
        });
    };

    const loadUserReportData = (page) => {
        let selectedAffiliatesList = selectedAffiliates?.map(affiliate => affiliate.affiliateId).join(",");
        getUserAffiliatesReportApiReq.request({ page: (parseInt(page, 0)), limit: 10, affilaiteIds: selectedAffiliatesList, daysSinceLogon: daysSinceLogon }, setReportApiResponse);
    }

    const handleExportToExcelClick = () => {
        let selectedAffiliatesList = selectedAffiliates?.map(affiliate => affiliate.affiliateId).join(",");
        downloadUserAffiliatesReportApiReq.request({ page: 1, affilaiteIds: selectedAffiliatesList, daysSinceLogon: daysSinceLogon });
    }

    const loadMoreReportDataClick = (e) => {
        loadUserReportData(currentPage + 1);
    }

    useEffect(() => {
        if(affiliateReportInfo?.reportHasBeenGenerated){
            generateFirstPageOfReport();
        }
    }, [daysSinceLogon])

    useEffect(() => {
        const reportRecords = processReportRecords(reportApiResponse?.data);
        setReportRecords(reportRecords);
        setCurrentPage(reportApiResponse?.pageInfo?.currentPage);
        setHasMorePages(reportApiResponse?.pageInfo?.totalPages > reportApiResponse?.pageInfo?.currentPage);
        pageProcessing.current = null;
    }, [reportApiResponse]);

    const processReportRecords = (items) => {
        const respRecords = currentPage === 0 ? [] : reportRecords;
        let currAffId = currentAffilaiteId;
        items?.map((rec) => {
            if (rec.affiliateId != currAffId) {
                setCurrentAffilaiteId(rec.affiliateId);
                currAffId = rec.affiliateId;
                respRecords.push({ ...rec, itemRow: false });
                respRecords.push({ ...rec, itemRow: true });
            } else {
                respRecords.push({ ...rec, itemRow: true });
            }
        });

        return respRecords;
    }

    const handleDeselectclick = (e) => {
        setSelectedAffiliates([]);
    }

    const handleAffiliateCheckboxSelect = (e) => {
        var affiliateList = selectedAffiliates;
        if (e.target.checked) {
            affiliateList = [...affiliateList, affiliates?.find(a => a.affiliateId == e.target.value)];
        } else {
            affiliateList = affiliateList.filter(item => item.affiliateId != e.target.value)
        }
        setSelectedAffiliates(affiliateList);
    };

    const handleSearchClick = (searchText) => {
        setAffiliatePage(0);
        setAffiliateSearchCriteria(searchText);
        setAffiliateReportInfo({
            ...affiliateReportInfo,
            searchCriteria: searchText
        });
    }

    useEffect(() => {
        if(affiliateReportInfo.reportHasBeenGenerated){
            loadUserReportData(1);
        }
    }, [])

    const handleResetSearch = (searchText) => {
        setAffiliatePage(0);
        setAffiliateSearchCriteria('');
        setAffiliateReportInfo({
            ...affiliateReportInfo,
            searchCriteria: ""
        });
    }

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleMenuClose = (days) => {
        setDaysSinceLogon(days)
        setAnchorEl(null);
    }

    const handleReportRecordMapping = (reportRecords) => {
        if(reportRecords.length >= 1){
            return mapRecordsToTable(reportRecords);
        } else{
            if(pageProcessing.current == null && reportRecords.length == 0 && affiliateReportInfo.reportHasBeenGenerated) {
                return (
                    <Grid item md={12} style={{paddingTop:'20%'}} sx={{minHeight: '50vh'}}>
                        <Grid item md={12}>
                            <ReportMessage >No records found!</ReportMessage> 
                        </Grid>
                    </Grid>
                ) 
            } else{
                return (
                    <Grid item md={12} style={{paddingTop:'20%'}} sx={{minHeight: '50vh'}}>
                        <Grid item md={12}>
                            <ReportMessage >Welcome!</ReportMessage> 
                        </Grid>
                        <Grid item md={12}>
                            <ReportMessage>Select Affiliate to Generate Report</ReportMessage> 
                        </Grid>
                    </Grid>
                ) 
            }
        }
    }

    const createLastLogonMenu = () => {
        return (
            <>
                <IconButton
                        aria-label='more'
                        id='long-button'
                        aria-controls={open ? 'login-date-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuClick}>
                        <MoreHoriz />
                </IconButton>

                <Menu id="login-date-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    PaperProps={{
                        style: {
                            maxWidth: 200,
                            wordWrap: true
                        }
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => handleMenuClose(daysSinceLogon)}
                    disableScrollLock={true}>
                        <MenuItem 
                            onClick={() => handleMenuClose(0)} 
                            style={{whiteSpace: 'normal'}}
                            selected={daysSinceLogon == 0 ? true : false}>All Agents</MenuItem>
                        <Divider sx={{my: 0}}/>
                        <MenuItem 
                            onClick={() => handleMenuClose(30)} 
                            style={{whiteSpace: 'normal'}}
                            selected={daysSinceLogon == 30 ? true : false}>Agents active over 30 days ago</MenuItem>
                        <Divider sx={{my: 0}}/>
                        <MenuItem 
                            onClick={() => handleMenuClose(60)} 
                            style={{whiteSpace: 'normal'}}
                            selected={daysSinceLogon == 60 ? true : false}>Agents active over 60 days ago</MenuItem>
                </Menu>
            </>
        )
    }

    const createReportHeader = () => {
        return (
            <>
                <Grid item md sm/>
                <Grid item>
                    <Tooltip arrow title="Export to Excel.">
                        <TableViewOutlinedIcon onClick={handleExportToExcelClick} sx={{marginTop: 1, marginRight: 1}}/>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Divider orientation='vertical'/>
                </Grid>
                <Grid item>
                    {createLastLogonMenu()}
                </Grid>
            </>
        )
    }

    const mapRecordsToTable = (reportRecords) => {
        return (
            <>
                <Grid item md={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><ListLabel sx={{ margin: "0 0 0 1em" }}>User Name</ListLabel></TableCell>
                                    <TableCell><ListLabel>Agent Name</ListLabel></TableCell>
                                    <TableCell><ListLabel>Email</ListLabel></TableCell>
                                    <TableCell><ListLabel>Status</ListLabel></TableCell>
                                    <TableCell><ListLabel>Created Date</ListLabel></TableCell>
                                    <TableCell><ListLabel>Last Modified</ListLabel></TableCell>
                                    <TableCell><ListLabel>Last Logged In</ListLabel></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportRecords?.map((item, idx) => (
                                    item.itemRow ? (
                                    <TableRow key={idx + "-" + item.userId + item.affiliateId}>
                                        <TableCell sx={{ paddingLeft: "2em" }}>{item.userName}</TableCell>
                                        <TableCell>{item.lastName}, {item.firstName}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>
                                            {new Date(item?.createdDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</TableCell>
                                        <TableCell>
                                            {new Date(item?.lastUpdatedDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</TableCell>
                                        <TableCell>
                                            {item?.lastLoggedInDate ? <Tooltip arrow title="Affiliate end date"><span>{new Date(item?.lastLoggedInDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span></Tooltip> : <UnknownLabel>Never Logged In</UnknownLabel>}
                                            {item.nonActivitySummary ? <span><br /><i>{item.nonActivitySummary}</i></span> : <></>}
                                        </TableCell>
                                    </TableRow>) : (
                                    <TableRow key={idx + item.userId + item.affiliateId}>
                                        <TableCell colSpan={7}>
                                            <FormValue>{item?.affiliateName}</FormValue>
                                        </TableCell>
                                    </TableRow>)
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    <Box alignItems="center" justifyContent="center" display="flex" sx={{ padding: "2em" }}>
                        {hasMorePages && <Button variant="outlined" text="Load more..." sx={{ maxWidth: "220px" }} onClick={loadMoreReportDataClick} />}
                    </Box>
                </Grid>
            </>
        );
    }

    return (<>
        <Grid container spacing={1} >
            <Grid item lg md sm>
                <Grid container spacing={1}>
                    <Grid item>
                        <MainTitle>Affiliate User Report</MainTitle>
                    </Grid>
                    
                    {createReportHeader()}

                    {handleReportRecordMapping(reportRecords)}
                </Grid>
            </Grid>
            <Drawer open={true} anchor='right' sx={{
                width: "20em",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "20em",
                    boxSizing: 'border-box',
                },
            }}
                variant="permanent">
                <Stack sx={{ marginTop: "5em", padding: "1em" }} spacing={1} direction="column">
                    {(affiliates?.length > 1 || affiliateSearchCriteria !== '') && <>
                    <FormValue>Search/Select Affiliate to Generate Report</FormValue>
                        <Divider />
                        <SearchControl placeholderText={affiliateSearchCriteria != "" ? affiliateSearchCriteria : "Search affiliates"} 
                            onSearch={handleSearchClick} 
                            onReset={handleResetSearch}
                            canBeReset={affiliateSearchCriteria != ""}
                            isExpanded={affiliateSearchCriteria != ""} >
                            </SearchControl>
                        <FormGroup>
                            <Stack direction='column'>
                                {affiliates?.map(item => (<>
                                    <Checkbox key={item.affiliateId} value={item.affiliateId} label={item.affiliateName} onChange={handleAffiliateCheckboxSelect}
                                        checked={selectedAffiliates ? selectedAffiliates.some(a => a?.affiliateId === item.affiliateId) : false} /></>))}

                                
                                <view style={{display: "flex", flexDirection: "row", backgroundColor: 'rgba(93, 184, 252, 0.1)', borderRadius: '10px'}}>
                                    <DeselectButton handleClick={handleDeselectclick} 
                                        disabledState={!selectedAffiliates?.length >= 1}
                                        selectedItemCount={selectedAffiliates?.length}/>

                                    <TablePagination component="div" sx={{padding: '0em 0 4em 0 ', '& .MuiTablePagination-actions': {
                                            margin:'0px'
                                        },
                                        marginRight: '0px',
                                        marginLeft: 'auto'}}
                                        count={totalAffiliatesCount ?? 0} page={affiliatePage}
                                        rowsPerPage={affiliatePerPageLimit} onPageChange={(e, value) => { setAffiliatePage(value) }}
                                        rowsPerPageOptions={[]}
                                    />
                                </view>
                            </Stack>
                        </FormGroup>
                        </>}                    
                    <Button text="Generate Report" onClick={e => generateFirstPageOfReport()} />
                </Stack>
            </Drawer>
        </Grid >
    </>);
}

export default AffiliateUserReport;