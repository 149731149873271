import { baseURL } from "~/config";

const URLs = {
    LOGIN_USER:`${baseURL}/auth`,
    RENEW_TOKEN:`${baseURL}/auth/renew`,
    GET_USERS: `${baseURL}/users`,
    GET_SECURITY_QUESTIONS: `${baseURL}/account/securityQuestions`,
    FORGOT_PASSWORD: `${baseURL}/auth/forgetpassword`,
    CHANGE_PASSWORD: `${baseURL}/account/changepassword`,
    AFFILIATES: `${baseURL}/affiliates`,
    ACCOUNT_NUMBER_SEARCH: `${baseURL}/accountInfo`,
    LOCATION_SEARCH: `${baseURL}/location`,
    BUYFLOW: `${baseURL}/buyflow`,
    REPORTS: `${baseURL}/reports`,
    GET_USER_TYPES: `${baseURL}/usertypes`,
    CHECK_USERNAME_AVAILABLE: `${baseURL}/users/usernameavailable`,
    MESSAGES: `${baseURL}/messages`
};

export default URLs;