import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const MainTitle = styled(Typography)({
    fontSize: '24px',
    fontWeight: "500",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#005a84',
    height: '30px',
    padding: '0'
});

export const ReportMessage = styled(Typography)({
    fontSize: '24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#005a84',
    height: '36px',
    padding: '0'
});

export const SubTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: "500",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#005a84',
    height: '30px',
    padding: '0'
});

export const FormHeader = styled(Typography)({
    fontFamily: 'SpectrumSans',
    fontSize: '20px',
    fontWeight: "normal",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003561',
    height: '30px',
    padding: '.1em 0'
});