import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export default function Password(props) {

    const { name, label, value, error = null, onChange, height = ".1em", ...other } = props;
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    return (
        <TextField fullWidth
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            height="0.8em"
            {...other}
            {...(error && { error: true, helperText: error })}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}