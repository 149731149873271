import React, { useEffect, useState, useRef } from "react";
import { Box, Breadcrumbs, Card, Grid, IconButton, InputAdornment, Link, Stack, TextField, Tooltip } from "@mui/material";
import SideBarMenu from "../components/header/sideBarMenu";
import { MainTitle, SubTitle, ReportMessage } from "../components/styledComponets/Titles";
import { Button, Form } from "../components/formFields";
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import useRequest from "../utils/hooks/useRequest";
import { getAccountNumbersByEmail } from "../api/accountSearchApi";
import { FormLabel, FormValue, ErrorLabel } from "../components/styledComponets/Labels";
import { isValidEmailAddress } from "../utils/fieldValidators";
import { useForm } from "../utils/hooks/useForm";
import useGlobalMessenger from '../utils/hooks/useGlobalMessenger';

function AccountNumberSearch() {

    const initialFormValues = {
        "accounts": []
    };

    const validateSearchText = (searchValue = searchText) => {
        let temp = { ...errors }
        if (searchValue !== undefined && searchValue !== "" && !isValidEmailAddress(searchValue)) {
            temp.email = 'Incorrect email format. Please enter in correct format';
            setApiResponse({});
        } else {
            temp.email = '';
        }
        setErrors({
            ...temp
        })

        return searchValue !== undefined && searchValue !== "" && Object.values(temp).every(e => e == "");
    }

    const {
        errors,
        setErrors
    } = useForm(initialFormValues, true, validateSearchText);

    const [searchText, setSearchText] = useState();
    const [hoverAccountNumber, setHoverAccountNumber] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState();
    const [apiResponse, setApiResponse] = useState({});
    const [accountInfoList, setAccountInfoList] = useState();
    const getAccountNumberSearchByEmailReq = useRequest(getAccountNumbersByEmail);
    const { setGlobalMessage } = useGlobalMessenger();

    useEffect(() => {
        if(validateSearchText(searchText)) {
            getAccountNumberSearchByEmailReq.request(searchCriteria, setApiResponse);
        }
    }, [searchCriteria]);

    const handleSearchClick = (e) => {
        e.preventDefault();
        setSearchCriteria(searchText);
    }

    useEffect(() => {
        if (apiResponse?.data) {
        setAccountInfoList(apiResponse?.data);
        setGlobalMessage({text: apiResponse?.data});
        } else if (apiResponse?.errors) {
            setGlobalMessage({ text: apiResponse?.errors?.message, variant: "error" });
        }
        }, [apiResponse]);

    return (<>
        <SideBarMenu selectedOption="AccountSearch" />
        <>
            <Breadcrumbs separator="|" sx={{ marginTop: '0', marginBottom: '1em' }}>
                <Link href="/home" underline="hover" sx={{ color: "#003561", fontSize: '14px', padding: '0' }}>Home</Link>
                <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Account Search</Link>
            </Breadcrumbs>
            <Grid container spacing={1}>
                <Grid item sm={11} md={10} lg={10}>
                    <MainTitle>Account Number Lookup</MainTitle>
                </Grid>
                <Grid item sm={11} md={10} lg={10}>
                    <Form>
                        <input type="submit" onClick={handleSearchClick} style={{ visibility: "hidden", height: "0em", position: 'absolute', zIndex: '100' }} />
                        <Box sx={{ boxShadow: 0, backgroundColor: 'rgba(93, 184, 252, 0.1)', padding: '1.2em 1.5em', borderRadius: '.5em' }}>
                            <TextField fullWidth sx={{ backgroundColor: '#fff', borderRadius: '1em', }}
                                placeholder="Email address.." onChange={(e) => setSearchText(e.target.value)}
                                // defaultValue={userManagementInfo.searchCriteria}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><IconButton onClick={handleSearchClick}><SearchIcon /></IconButton></InputAdornment>,
                                    endAdornment: <Button text="Search" sx={{ margin: "1em 0 em", maxWidth: "25em" }} onClick={handleSearchClick} variant="contained" />
                                }}>
                            </TextField>
                            <ErrorLabel><br/>{errors.email}</ErrorLabel>
                        </Box>
                    </Form>
                </Grid>
                <Grid item sm={11} md={10} lg={10} style={{ paddingTop: '2%' }} >
                        <SubTitle>To retrieve an account number, a verification Email can only be sent by Charter to a registered Email address that exists on a Charter account.<br/>
                            <br/>If a match is received, the customer will receive an email with their account number.  Agent will verify that the customer name, address, and account number match.<br/>
                            <br/>If matched, customer can provide their account number to the agent for entry into the Buyflow ordering system.
                        </SubTitle>
                </Grid>
            </Grid>
        </>
    </>);
}

export default AccountNumberSearch;