import { RESET, SET_CONFIG_INFO } from './configActionTypes';
import { getDefaultContext } from './configContext';

const configReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_CONFIG_INFO:
      return {
        ...state,
        configInfo: {
          ...state.configInfo,
          ...payload
        }
      };
    case RESET:
      return { ...state, ...getDefaultContext() };
    default:
      return state;
  }
};

export { configReducer as default };
