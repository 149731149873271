import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Logo from '../../assets/spectrum-logo-white.svg';
import Divider from "@mui/material/Divider";
import AccountMenu from './AccountMenu';
import { useContext } from "react";
import GlobalContext from "../../context/globalContext/globalContext";

const AppBarWrapper = styled(MuiAppBar, {})(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}));

export default function Header() {
    const { userInfo } = useContext(GlobalContext);
    return (
        <Box>
            <CssBaseline />
            <AppBarWrapper position="fixed">
                <Toolbar sx={{
                    pr: '24px',
                }}>
                    <a href='/'>
                        <img src={Logo} style={{ paddingLeft: '2.8em' }} alt='Spectrum' />
                    </a>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, paddingLeft: '1em' }}>
                        Retail Portal v2.0
                    </Typography>
                    {userInfo.userName && <AccountMenu />}
                </Toolbar>
            </AppBarWrapper>
            <Divider sx={{ paddingBottom: '4em', borderWidth: '0em' }} />
        </Box>
    );
}
