import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainTitle, FormText } from "../../styledComponets/Titles"
import { Stack } from "@mui/system";
import Button from "../../formFields/Button"
import { Password } from "../../formFields";
import { useForm } from "../../../utils/hooks/useForm";
import { changeUserPasswordApi } from "../../../api/accountApi";
import useGlobalMessenger from "../../../utils/hooks/useGlobalMessenger";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { FormLabel } from "../../styledComponets/Labels";
import { ROUTES } from '../../../utils/consts';
import { useNavigate } from 'react-router';
import { clearAuthToken } from "../../../utils/authHelper";
import GlobalContext from "../../../context/globalContext/globalContext";
import { defaultUserInfo } from "../../../utils/globalStateHelper";
import useRequest from "../../../utils/hooks/useRequest";

const minLength = 8;

const validateMinLength = (input) => {
    if(!input || input.trim().length < minLength){
        return false;
    }
    return true;;
}

const validateAlphaNumericCharacters = (input) => {
    const alphaRegex = /[a-zA-Z]+/;
    const numberRegex = /[0-9]+/;
    return alphaRegex.test(input) && numberRegex.test(input);
}

const validateSpecialCharacters = (input) => {
    const specialCharRegex = /[^a-zA-Z0-9]+/;
    if (!input || input.length === 0) {
        return false;
    }
    return specialCharRegex.test(input);
}

const validateBothCasedCharacters = (input) => {
    const lowerCaseRegex = /[a-z]+/;
    const upperCaseRegex = /[A-Z]+/;
    if (!input || input.length === 0) {
        return false;
    }
    return lowerCaseRegex.test(input) && upperCaseRegex.test(input);
}


function ChangePassword() {
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(GlobalContext);
    const { setGlobalMessage } = useGlobalMessenger();

    const changeUserPasswordApiReq = useRequest(changeUserPasswordApi);

    const initialFormValue = { oldPassword: "", newPassword: "", confirmPassword: "" };
    const [apiResponse, setApiResponse] = useState({});

    const validateForm = (fieldValues = values, ...rest) => {
        let temp = { ...errors }
        if ('oldPassword' in fieldValues)
            temp.oldPasswordError = fieldValues.oldPassword ? "" : "This field is required."
        if ('newPassword' in fieldValues) {
            if (fieldValues.newPassword) {
                if (!validateMinLength(fieldValues.newPassword, 8)) {
                    temp.newPasswordError = "Should contain at least 8 characters.";
                }
                else if (!validateAlphaNumericCharacters(fieldValues.newPassword)){
                    temp.newPasswordError = "Should contain letters and numbers";
                }
                else if (!validateSpecialCharacters(fieldValues.newPassword)) {
                    temp.newPasswordError = "Should contain special character."
                } else if (!validateBothCasedCharacters(fieldValues.newPassword)) {
                    temp.newPasswordError = "Should contain lower and upper case characters."
                } else {
                    temp.newPasswordError = "";
                }
            } else {
                temp.newPasswordError = "This field is required."
            }
        }
        if ('confirmPassword' in fieldValues) {
            if (!fieldValues.confirmPassword) {
                temp.confirmPasswordError = "This field is required.";
            } else if (values.newPassword !== fieldValues.confirmPassword) {
                temp.confirmPasswordError = "New and Confirm passwords should be matching."
            } else {
                temp.confirmPasswordError = "";
            }
        }

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    };

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFormValue, true, validateForm);

    const handleSubmit = () => {
        if (validateForm(values)) {
            changeUserPasswordApiReq.request(values, setApiResponse);
        }
    }

    const [hasAlphaNumeric, setHasAlphaNumeric] = useState(false);
    const [hasSpecialCharecters, setHasSpecialCharectors] = useState(false);
    const [hasBothCasedLetters, setHasBothCasedLetters] = useState(false);
    const [hasMinCharacters, setHasMinCharacters] = useState(false);

    useEffect(() => {
        setHasAlphaNumeric(validateAlphaNumericCharacters(values.newPassword));
        setHasSpecialCharectors(validateSpecialCharacters(values.newPassword));
        setHasBothCasedLetters(validateBothCasedCharacters(values.newPassword));
        setHasMinCharacters(validateMinLength(values.newPassword));
    }, [values.newPassword]);

    useEffect(() => {
        if (apiResponse?.data) {
            resetForm();
            setGlobalMessage({ text: apiResponse?.data });
            clearAuthToken();
            setUserInfo({ ...defaultUserInfo });
            setTimeout(() => navigate(ROUTES.LOGIN_ROUTE), 10);
        }
    }, [apiResponse]);

    return (
        <Grid container spacing={1} sx={{ textAlign: "left", marginTop: '1em' }}>
            <Grid item sm={12} >
                <MainTitle>Change Password</MainTitle>
                <hr />
            </Grid>
            <Grid item md={4} sm={6} sx={{ textAlign: "left" }} >
                <Grid container rowSpacing={1}>
                    <Grid item sm={12}>
                        <FormLabel>Current password</FormLabel>
                        <Password name="oldPassword" value={values.oldPassword} onChange={handleInputChange}
                            error={errors.oldPasswordError} />
                    </Grid>
                    <Grid item sm={12}>
                        <FormLabel>New password</FormLabel>
                        <Password name="newPassword" value={values.newPassword} onChange={handleInputChange}
                            error={errors.newPasswordError} />
                    </Grid>
                    <Grid item sm={12}>
                        <FormLabel>Confirm password</FormLabel>
                        <Password name="confirmPassword" value={values.confirmPassword} onChange={handleInputChange}
                            error={errors.confirmPasswordError} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={7} sm={5} sx={{ textAlign: "left", marginTop: "1em", marginLeft: '2em' }}>
                <Stack direction='column' spacing={3.5} >
                    <FormLabel>Passwords must be</FormLabel>
                    <Stack direction='row' spacing={2}>
                        {(hasAlphaNumeric && hasMinCharacters) ? (<CheckCircleIcon sx={{ color: 'green' }} />) : (<CheckCircleOutlinedIcon />)}
                        <FormLabel> At least 8 alphanumeric characters in length</FormLabel>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        {hasSpecialCharecters ? (<CheckCircleIcon sx={{ color: 'green' }} />) : (<CheckCircleOutlinedIcon />)}
                        <FormLabel>contain at least one special character</FormLabel>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        {hasBothCasedLetters ? (<CheckCircleIcon sx={{ color: 'green' }} />) : (<CheckCircleOutlinedIcon />)}
                        <FormLabel>contain both uppercase and lowercase letters</FormLabel>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item sm={12}><hr /></Grid>
            <Grid item md={4} sm={6}>
                <Button onClick={handleSubmit} text="Update Password" />
            </Grid><Grid item sm />
        </Grid>
    );
}

export default ChangePassword;