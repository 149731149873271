import axios from "axios";
import URLs from "../utils/urls";
import { getApiOptions, errorFormat } from "../utils/apiHelpers";

export async function getForgotPasswordQuestion(userName, setResponse) {
    const ops = {
        method: 'get',
        url: URLs.FORGOT_PASSWORD,
        ...getApiOptions(),
        params: { userName }
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function resetUserPassword(payload, setResponse) {
    const ops = {
        method: 'post',
        url: URLs.FORGOT_PASSWORD,
        ...getApiOptions(),
        data: payload
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}


export async function changeUserPasswordApi(payload, setResponse) {
    const ops = {
        method: 'post',
        url: URLs.CHANGE_PASSWORD,
        ...getApiOptions(),
        data: payload
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}