import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Card, Grid, IconButton, Link, Stack, Tooltip } from "@mui/material";
import SideBarMenu from "../components/header/sideBarMenu";
import { MainTitle, ReportMessage, SubTitle } from "../components/styledComponets/Titles";
import { Button, Form, Input } from "../components/formFields";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import useRequest from "../utils/hooks/useRequest";
import { ErrorLabel, FormLabel, FormValue } from "../components/styledComponets/Labels";


import { getLocationDetailsByAddress } from "../api/locationSearchApi";
import { useForm } from "../utils/hooks/useForm";

function AddressSearch() {
    const [searchApiResponse, setSearchApiResponse] = useState();
    const [addressList, setAddressList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [responseError, setResponseError] = useState();
    const [hoverBillerLocationId, setHoverBillerLocationId] = useState(null);

    const validateAddressLines = (input) => {
        const addressRegex = /^[a-zA-Z0-9 .'\-\#@%&\\/]*$/;

        return addressRegex.test(input);
    }

    const validateZipCode = (input) => {
        const zipRegex = /^[0-9]*$/;

        return zipRegex.test(input);
    }

    const initialFormValues = {
        "addressLine1": "",
        "addressLine2": "",
        "zipCode": ""
    };

    const validateSearchparams = (fieldValues = values) => {
        let temp = { ...errors }

        if (!fieldValues.addressLine1) {
            temp.addressLine1 = "This field is required.";
        } else if (!validateAddressLines(fieldValues.addressLine1)){
            temp.addressLine1 = "Invalid input."
        } 
        else {
            temp.addressLine1 = "";
        }

        if(fieldValues.addressLine2){
            if(!validateAddressLines(fieldValues.addressLine2)){
                temp.addressLine2 = "Invalid input."
            }
        }

        if (!fieldValues.zipCode) {
            temp.zipCode = "Zip is Mandatory"
        } else if (!validateZipCode(fieldValues.zipCode)){
            temp.zipCode = "Zip code can only contain numbers"
        }else {
            temp.zipCode = "";
        }

        setErrors({
            ...temp
        })
        if (fieldValues == values)
            return Object.values(temp).every(x => x == "");
    }

    const {
        values,
        errors,
        setErrors,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(initialFormValues, false, validateSearchparams);

    const getLocationDetailsByAddressReq = useRequest(getLocationDetailsByAddress);
    const [formNotCleared, setFormNotCleared] = useState(true);

    const handleSearchClick = (e) => {
        e.preventDefault();
        if (validateSearchparams(values)) {
            setFormNotCleared(true);
            setAddressList(null);
            setResponseError(null);
            getLocationDetailsByAddressReq.request({ addressLine1: values.addressLine1, addressLine2: values.addressLine2, zipCode: values.zipCode }, setSearchApiResponse);
        }
    }

    const handleResponseRendering = () => {
        if(addressList?.length >= 1){
            return (
                <Grid container>
                    <Grid item md={12} sx={{ marginTop: "1em" }}>
                        {totalRecords <= 10 ?
                            <SubTitle> Total {addressList?.length} Address found</SubTitle>
                            :
                            <SubTitle>
                                <ErrorLabel>
                                    Exact match not found, please include all dwelling information in ‘address line 2’ such as apartment number, floor, building, etc. and retry
                                </ErrorLabel>
                            </SubTitle>}
                    </Grid>
                    <Grid item md={12}>
                        {addressList?.map(addr => (
                            <Card sx={{ padding: "1em", marginBottom: ".8em" }}>
                                <Grid container>
                                    <Grid item sm={6} md={4}>
                                        <Stack direction="row">
                                            <FormLabel>Location Id - </FormLabel>
                                            <FormValue>{addr?.billerLocationId}</FormValue>
                                            <IconButton sx={{ paddingTop: "0em" }}>
                                                <Tooltip title={addr.billerLocationId === hoverBillerLocationId ? "Copied!" : "Copy"}
                                                    placement="bottom-end">
                                                    <ContentCopyOutlinedIcon onClick={() => {
                                                        navigator.clipboard.writeText(addr.billerLocationId);
                                                        setHoverBillerLocationId(addr.billerLocationId);
                                                    }} />
                                                </Tooltip>
                                            </IconButton>
                                        </Stack>
                                    </Grid>
                                    <Grid item >
                                        <FormValue>
                                            {addr.addressLine1 ? `${addr.addressLine1}, ` : ''}
                                            {addr.addressLine2 ? `${addr.addressLine2}, ` : ''}
                                            {addr.city ? `${addr.city}, ` : ''}
                                            {addr.zipCode ? `${addr.zipCode} ` : ''}
                                        </FormValue>
                                    </Grid>
                                </Grid>
                            </Card>
                        ))}
                    </Grid>
                </Grid>
            )
        } else{
            if(getLocationDetailsByAddressReq.hasBeenInvoked && !getLocationDetailsByAddressReq.inprogress && formNotCleared){
                return (
                    <Grid item md={12} style={{paddingTop:'20%'}} sx={{minHeight: '50vh'}}>
                        <Grid item md={12}>
                            <ReportMessage>{responseError ? responseError.message : "Please enter a valid address"}</ReportMessage> 
                        </Grid>
                    </Grid>
                )
            }
        }
    }

    useEffect(() => {
        setAddressList(searchApiResponse?.data.data);
        setTotalRecords(searchApiResponse?.data.pageInfo?.totalRecords);
        setResponseError(searchApiResponse?.data.errors)
        console.log(getLocationDetailsByAddressReq)
        console.log(searchApiResponse)
    }, [searchApiResponse]);

    return (<>
        <SideBarMenu selectedOption="AddressSearch" />
        <>
            <Breadcrumbs separator="|" sx={{ marginTop: '0', marginBottom: '1em' }}>
                <Link href="/home" underline="hover" sx={{ color: "#003561", fontSize: '14px', padding: '0' }}>Home</Link>
                <Link underline="hover" sx={{ fontSize: '14px', color: "#63738a", padding: '0' }}>Location Id Search</Link>
            </Breadcrumbs>
            <Grid container spacing={1}>
                <Grid item sm={11} md={10} lg={10}>
                    <MainTitle>Location Id Lookup</MainTitle>
                    <SubTitle>Search existing address to find location Id</SubTitle>
                </Grid>
                <Grid item sm={11} md={10} lg={10}>
                    <Form>
                        <Box sx={{ boxShadow: 0, backgroundColor: 'rgba(93, 184, 252, 0.1)', padding: '1.2em 1.5em', borderRadius: '.5em' }}>
                            <Grid container spacing={1}>
                                <Grid item sm={5} md={5} lg={3}>
                                    <Input name="addressLine1" value={values.addressLine1} sx={{ backgroundColor: '#fff' }} fullWidth label="Address Line 1"
                                        onChange={handleInputChange} error={errors.addressLine1} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={2}>
                                    <Input name="addressLine2" fullWidth label="Address Line 2" variant="outlined" sx={{ backgroundColor: '#fff' }}
                                        onChange={handleInputChange} value={values.addressLine2} 
                                        error={errors.addressLine2}/>
                                </Grid>
                                <Grid item sm={3} md={3} lg={2}>
                                    <Input name="zipCode" fullWidth label="Zip" sx={{ backgroundColor: '#fff', borderRadius: '1em', }}
                                        onChange={handleInputChange} value={values.zipCode} error={errors.zipCode} />
                                </Grid>
                                <Grid item sm={12} md={12} lg={5}>
                                    <Stack direction="row" spacing={2}>
                                        <Button text="Clear All" sx={{ margin: "1em 0 em", height: "3.8em", backgroundColor: '#fff' }} variant="outlined"
                                            onClick={() => {
                                                resetForm();
                                                setAddressList(null);
                                                setTotalRecords(0);
                                                setFormNotCleared(false)
                                            }} />
                                        <Button text="Search" sx={{ margin: "1em 0 em", height: "3.8em", }} variant="contained"
                                            onClick={handleSearchClick} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </Grid>
                <Grid item sm md lg />
                <Grid item sm={11} md={10} lg={10}>
                    {handleResponseRendering()}
                </Grid>
            </Grid>
        </>
    </>);
}

export default AddressSearch;