import React, { useEffect, useState, useCallback } from 'react';
import { Card, CircularProgress, Grid, Paper, TablePagination } from '@mui/material';
import { FormHeader, MainTitle, SubTitle } from '../styledComponets/Titles';
import useRequest from '../../utils/hooks/useRequest';
import { getUserAffiliateDetailsApi } from '../../api/affiliateStoreApi';
import { FormLabel, FormValue, ListLabel, UnknownLabel } from '../styledComponets/Labels';
import { LabelContainer } from '../styledComponets/Containers';
import { checkTextFitsInComponent } from '../../utils/fieldValidators'

function ViewAffiliateDetails(props) {
    let labelRef = useCallback(node => {
        if(node !== null){
            labelRef.current = node;
        }
    }, []);
    const [affiliateDetail, setAffiliateDetail] = useState();
    const [apiResponse, setApiResponse] = useState({});
    const [storePage, setStorePage] = useState(0);
    const [storesPerPage, setStoresPerPage] = useState(15);
    const [stores, setStores] = useState();

    const getaffiliateDetailApiReq = useRequest(getUserAffiliateDetailsApi);

    const { affiliateId } = props;

    useEffect(() => {
        getaffiliateDetailApiReq.request(affiliateId, setApiResponse);
    }, [affiliateId]);

    useEffect(() => {
        setAffiliateDetail(apiResponse?.data)
    }, [apiResponse]);

    const setStoresByPage = (page) => {
        let startIndex = ((page ?? 0) * storesPerPage);
        setStores(affiliateDetail?.stores?.slice(startIndex, startIndex + storesPerPage));
    };

    useEffect(() => {
        setStoresByPage(storePage);
    }, [storePage, storesPerPage, affiliateDetail]);

    return (affiliateDetail ? (<>
        <Grid container spacing={1} >
            <Grid item sm={12}>
                <MainTitle>View Affiliate Details </MainTitle>
            </Grid>
            <Grid item sm={12} sx={{ marginTop: "1em" }}>
                <Grid container spacing={.5}>
                    <Grid item sm={3} md={2}>
                        <FormLabel>Affiliate ID</FormLabel>
                    </Grid>
                    <Grid item sm={9}>
                        <FormValue>{affiliateDetail.affiliateId} </FormValue>
                    </Grid>
                    <Grid item sm={3} md={2}>
                        <FormLabel>Affiliate name</FormLabel>
                    </Grid>
                    <Grid item sm={9}>
                        <FormValue>{affiliateDetail.affiliateName}</FormValue>
                    </Grid>
                    <Grid item sm={3} md={2}>
                        <FormLabel>Status</FormLabel>
                    </Grid>
                    <Grid item sm={9}>
                        <FormValue>{affiliateDetail.active ? "Active" : "Inactive"}</FormValue>
                    </Grid>
                    <Grid item sm={3} md={2}>
                        <FormLabel>Start date</FormLabel>
                    </Grid>
                    <Grid item sm={9}>
                        <FormValue> {new Date(affiliateDetail?.startDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</FormValue>
                    </Grid>
                    <Grid item sm={3} md={2}>
                        <FormLabel>End date</FormLabel>
                    </Grid>
                    <Grid item sm={9}>
                        <FormValue> {affiliateDetail?.endDate ? new Date(affiliateDetail?.endDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }) : <UnknownLabel>Unavailable</UnknownLabel>}</FormValue>
                    </Grid>
                    {affiliateDetail?.stores && <>
                        <Grid item sm={12} md={12} sx={{ marginTop: "1em" }}>
                            <FormHeader>Associated Stores</FormHeader>
                            <SubTitle>{affiliateDetail.stores?.length} Stores</SubTitle>
                            <Grid container rowSpacing={1} columnSpacing={2}>
                                {stores?.map(store => (
                                    <Grid item key={store.storeId} md={4} sm={6} title={store.storeName}>
                                    <LabelContainer>
                                        <ListLabel ref={labelRef}>
                                            {checkTextFitsInComponent(store.storeName, labelRef)}
                                        </ListLabel>
                                    </LabelContainer>
                                </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {((affiliateDetail?.stores?.length ?? 1) > 8) &&
                            <Grid item sm={12} md={12} sx={{ marginTop: "1em" }}>
                                <TablePagination component="div" sx={{ bgcolor: 'rgba(93, 184, 252, 0.1)', padding: '0em 0 4em 0 ', borderRadius: '10px' }}
                                    count={affiliateDetail?.stores?.length ?? 0} page={storePage}
                                    rowsPerPage={storesPerPage}
                                    onPageChange={(e, value) => { setStorePage(value) }}
                                    rowsPerPageOptions={[12, 15, 30, 60]}
                                    onRowsPerPageChange={(e) => setStoresPerPage(e.target.value)}
                                />
                            </Grid>
                        }
                    </>
                    }
                </Grid>
            </Grid >
        </Grid >

    </>) : (<CircularProgress color="inherit" />));
}

export default ViewAffiliateDetails;