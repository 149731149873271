import { FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Paper, Radio, Step, StepLabel, Stepper, TablePagination, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainTitle, SubTitle } from "../../styledComponets/Titles"
import Button from "../../formFields/Button"
import { Form, Input, PhoneNumber, RadioGroup } from "../../formFields";
import { getUserAffiliatesApi } from "../../../api/affiliateStoreApi";
import { getUserTypesApi } from "../../../api/userTypeApi";
import { useForm } from "../../../utils/hooks/useForm";
import useGlobalMessenger from "../../../utils/hooks/useGlobalMessenger";
import { getUserDetailApi, updateUserApi } from '../../../api/usersApi';

import { checkUserNameIfAvailable, createUserApi } from "../../../api/usersApi";
import { ErrorLabel, FormLabel, FormValue, SectionLabel, StepperLabel, SuccessLabel } from "../../styledComponets/Labels";
import { containsSpecialChars, isUsernameContainsSpecialChars, isValidEmailAddress, isValidPhoneNumber } from "../../../utils/fieldValidators";
import useRequest from "../../../utils/hooks/useRequest";
import Checkbox from "../../formFields/CheckBox";
import SearchControl from "../../formFields/SearchControl";
import { useContext } from "react";
import globalContext from "../../../context/globalContext/globalContext";
import configContext from "../../../context/configContext/configContext";

function CreateEditUser({ title, onClose, editMode, userId }) {
    const getUserTypesApiReq = useRequest(getUserTypesApi);
    const getUserAffiliatesApiReq = useRequest(getUserAffiliatesApi);
    const getUserDetailApiReq = useRequest(getUserDetailApi);
    const checkUserNameIfAvailableApiReq = useRequest(checkUserNameIfAvailable);
    const createUserApiReq = useRequest(createUserApi);
    const updateUserApiReq = useRequest(updateUserApi);

    const initialFormValues = {
        "userTypeId": 1,
        "userType": {},
        "affiliates": [],
        "userName": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "affiliateAgentId": ""
    };

    const validateUserTypeForm = (fieldValues = values) => {
        let temp = {}
        if ('userTypeId' in fieldValues)
            temp.userTypeId = fieldValues.userTypeId > 0 ? "" : "User'Type' is required.";
        if (fieldValues.userType?.hasAffiliateRestriction && 'affiliates' in fieldValues)
            temp.affiliates = (fieldValues.affiliates?.length > 0 || fieldValues.userTypeId == 1) ? "" : "Select affiliate(s) to proceed";
        else {
            temp.affiliates = "";
        }
        setErrors({
            ...errors,
            ...temp
        })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const validatePersonalInfoForm = (fieldValues = values) => {
        let temp = { ...errors }
        if ('userName' in fieldValues) {
            if (fieldValues.userName) {
                if (/\s/g.test(fieldValues.userName)) {
                    temp.userNameError  = "Username must not contain blank space.";
                } else if (isUsernameContainsSpecialChars(fieldValues.userName)) {
                    temp.userNameError = "Username cannot contain special charecters.";
                } else if (fieldValues.userName.length < 5 || fieldValues.userName.length > 19) {
                    temp.userNameError = "Username need to be 5 to 19 charecters.";
                } else {
                    temp.userNameError = "";
                }

            } else {
                temp.userNameError = "This field is required.";
            }
        }

        if ('firstName' in fieldValues){
            if(!fieldValues.firstName){
                temp.firstName = "This field is required."
            } else if(containsSpecialChars(fieldValues.firstName)){
                temp.firstName = "First name cannot contain special characters"
            } else if(fieldValues.firstName.length > 35){
                temp.firstName = "First name must be 35 characters or less"
            } else{
                temp.firstName = "";
            }
        }
        if ('lastName' in fieldValues){
            if(!fieldValues.lastName){
                temp.lastName = "This field is required."
            } else if(containsSpecialChars(fieldValues.lastName)){
                temp.lastName = "Last name cannot contain special characters"
            } else if(fieldValues.lastName.length > 35){
                temp.lastName = "Last name must be 35 characters or less"
            } else{
                temp.lastName = "";
            }
        }
        if ('email' in fieldValues) {
            if(!fieldValues.email){
                temp.email = "This field is required"
            }else if (!isValidEmailAddress(fieldValues.email)) {
                temp.email = "Invalid email"
            } else{
                temp.email = "";
            }
        }
        if ('phoneNumber' in fieldValues) {
            temp.phoneNumber = "";
            if (fieldValues.phoneNumber && !isValidPhoneNumber(fieldValues.phoneNumber)) {
                temp.phoneNumber = "Invalid phone number"
            } else{
                temp.phoneNumber = "";
            }
        }
        if (configInfo?.AllowExplicitAgentId && 'affiliateAgentId' in fieldValues){
            if (!fieldValues.affiliateAgentId){
                temp.affiliateAgentId = "This field is required"
            } else if (fieldValues.affiliateAgentId.length > 35) {
                temp.affiliateAgentId = "Affiliate ID must be 35 characters or less";
            } else{
                temp.affiliateAgentId = "";
            }
        }

        setErrors({
            ...temp
        })
        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const validateForm = (fieldValues) => {
        return (validateUserTypeForm(fieldValues) && validatePersonalInfoForm(fieldValues));
    }

    const {
        values,
        errors,
        setErrors,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(initialFormValues, true, validatePersonalInfoForm);

    const [apiResponse, setApiResponse] = useState({});

    useEffect(() => {
        if (apiResponse?.data) {
            setValues({ ...apiResponse.data });
        }
    }, [apiResponse?.data]);

    const {userInfo} = useContext(globalContext);
    const {configInfo} = useContext(configContext);

    const [userTypeResp, setUserTypeResp] = useState({});
    const [affiliatesRes, setAffiliateResp] = useState([]);
    const [createUserResp, setCreateUserResp] = useState([]);

    const [activeStep, setActiveStep] = useState(0);
    const [userTypes, setUserTypes] = useState([]);

    const [affiliatePage, setAffilaitePage] = useState(0);
    const [affiliatesPerPage, setAffilaitesPerPage] = useState(15);
    const [totalAffilaites, setTotalAffilaites] = useState(0);
    const [affiliates, setAffiliates] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');

    useEffect(() => {
        if (editMode) {
            getUserDetailApiReq.request(userId, setApiResponse);
        }
        getUserTypesApiReq.request(setUserTypeResp);
    }, []);

    useEffect(() => {
        if (userTypeResp?.data) {
            setUserTypes(userTypeResp.data);
        } 
    }, [userTypeResp]);

    useEffect(() => {
        if (values.userTypeId == values.userType?.id) { return; }
        if (values.userTypeId > 0) {
            setValues({
                ...values,
                userType: userTypes?.find(ut => ut.id == values.userTypeId)
            });
        }
    }, [values.userTypeId])

    useEffect(() => {
        var tempUserTypeId = values.userType ? values.userType.id : 0;
        if (!values.userType?.operateMultipleAffilites && values.affiliates?.length > 1) {
            setValues({
                ...values,
                userTypeId: tempUserTypeId,
                affiliates: [values.affiliates[0]]
            });
        } else {
            setValues({
                ...values,
                userTypeId: tempUserTypeId
            });
        }
    }, [values.userType])

    useEffect(() => {
        getUserAffiliatesApiReq.request({ page: (parseInt(affiliatePage, 0) + 1), limit: affiliatesPerPage, affiliateName: searchCriteria }, setAffiliateResp);
    }, [affiliatePage, affiliatesPerPage, searchCriteria]);

    const getInactiveUserAffiliateIdsInSearch = () => {
        var searchCriteriaUpperCase = searchCriteria.toUpperCase();
        var inactiveSearchedUserAffiliates = values?.affiliates?.filter(item => item.affiliateName.toUpperCase().match(searchCriteriaUpperCase) && !item.active && !item.isActive)
        return [...inactiveSearchedUserAffiliates.map(item => item.affiliateId)]
    };

    const handleAffiliateResponse = () => {
        if(values?.affiliates){
            var inactiveUserAffiliateIds = getInactiveUserAffiliateIdsInSearch()
            var orderedAffiliates = [...values?.affiliates?.filter(item => !item.active && !item.isActive && inactiveUserAffiliateIds.includes(item.affiliateId))];

            if(affiliatePage == 0 && orderedAffiliates.length > 0){
                if(affiliatesRes?.data){
                    orderedAffiliates = orderedAffiliates.concat(affiliatesRes.data);
                } 
                setAffiliates(orderedAffiliates)
            } else {
                setAffiliates(affiliatesRes.data);
            }
        } else{
            setAffiliates(affiliatesRes.data)
        }
        
    }

    useEffect(() => {
        handleAffiliateResponse(); 
        setTotalAffilaites(affiliatesRes?.pageInfo?.totalRecords);
    }, [affiliatesRes, values?.userType]);

    const handleAffiliateRowsPerPageChange = (event) => {
        setAffilaitesPerPage(parseInt(event.target.value, 0));
        setAffilaitePage(0);
    };

    const handleAffiliateRadioSelect = (e) => {
        var affiliate = affiliates?.find(a => a.affiliateId == e.target.value)

        if(affiliate){
            setValues({
                ...values,
                affiliates: [affiliate]
            });
        } else{
            e.preventDefault()
        }
        
    };

    const handleAffiliateCheckboxSelect = (e) => {
        var affiliateList = values.affiliates ? values.affiliates : [];
        if (e.target.checked) {
            affiliateList = [...affiliateList, affiliates?.find(a => a.affiliateId == e.target.value)];
            setErrors({
                ...errors,
                affiliates: ""
            })
        } else {
            if(affiliateList.length > 1){
                affiliateList = affiliateList.filter(item => item.affiliateId != e.target.value)
            } else{
                e.preventDefault();
                setErrors({
                    ...errors,
                    affiliates: "User must be associated with at least 1 affiliate"
                })
            }
        }
        setValues({
            ...values,
            affiliates: affiliateList
        });
    };

    const [userNameAvailableResp, setUserNameAvailableResp] = useState(null);
    const [userNameAvailable, setUserNameAvailable] = useState(null);

    const handleCheckUserNameAvailabilityClick = (e) => {
        e.preventDefault();
        let temp = { ...errors }
        if (values.userName == "") {
            temp.userNameError = "This field is required."
            setErrors({
                ...temp
            })
        } else {
            temp.userNameError = ""
            setErrors({
                ...temp
            })
            checkUserNameIfAvailableApiReq.request(values.userName, setUserNameAvailableResp);
        }
    };

    const [availCheckedUserName, setAvailCheckedUserName] = useState("");
    useEffect(() => {
        if (userNameAvailableResp) {
            setUserNameAvailable(userNameAvailableResp?.data == true);
            setAvailCheckedUserName(values.userName);
        }
    }, [userNameAvailableResp])

    const handleAddPersonalInfoClick = (e) => {
        e.preventDefault();
        if (validateUserTypeForm()) {
            setActiveStep(1);
        }
    };

    const handleViewProfileSummaryClick = (e) => {
        e.preventDefault();
        if (validatePersonalInfoForm(values)) {
            setActiveStep(2);
            setValues({
                ...values,
                affiliates: values?.affiliates?.sort((a, b) => a.affiliateName.localeCompare(b.affiliateName))
            })
        }
    };

    const handleCreateUserClick = (e) => {
        e.preventDefault();
        if (editMode) {
            updateUserApiReq.request(values, setCreateUserResp);
        } else {
            createUserApiReq.request(values, setCreateUserResp);
        }
    };

    const { setGlobalMessage } = useGlobalMessenger();
    useEffect(() => {
        if (createUserResp?.data && !createUserResp?.errors) {
            setGlobalMessage({ text: editMode ? "User updated Successfully!" : "User Created Successfully!" });
            resetForm();
            onClose(createUserResp?.data);
        }
    }, [createUserResp]);

    const handleAffilaitePageChange = (event, value) => {
        setAffilaitePage(value);
    };

    const handleAffiliateSearchClick = (searchText) => {
        setAffilaitePage(0);
        setSearchCriteria(searchText);
    }

    const handleAffiliateSearchResetClick = () => {
        setAffilaitePage(0);
        setSearchCriteria('');
    }

    return (
        <Grid container spacing={1}>
            <Grid item sm={12} >
                <MainTitle>{title ? title : "Create New User"}</MainTitle>
            </Grid>
            <Grid item sm={12}>
                <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: "1em" }} >
                    <Step key='userCreateStep1'>
                        <StepLabel><StepperLabel>User Type and Affiliate(s)</StepperLabel></StepLabel>
                    </Step>
                    <Step key='userCreateStep2'>
                        <StepLabel><StepperLabel>Personal Information</StepperLabel></StepLabel>
                    </Step>
                    <Step key='userCreateStep3'>
                        {<StepLabel><StepperLabel>Profile Summary</StepperLabel></StepLabel>}
                    </Step>
                </Stepper>
                <>
                    {activeStep === 0 ? (
                        <Form>
                            <Grid container sx={{ padding: '1em' }} spacing={2}>
                                <Grid item sm={12}>
                                    <Paper sx={{ padding: '2em', borderRadius: '.5em' }} variant='outlined'>
                                        <Grid item sx={{ paddingBottom: '1em' }}>
                                            <SectionLabel>Select User'Type'</SectionLabel>
                                        </Grid>

                                        <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                            {errors.userTypeId === "" ? <></> : <ErrorLabel>{errors.userTypeId}</ErrorLabel>}
                                        </Grid>
                                        <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                            {values.userType &&
                                                <RadioGroup name="userTypeId" itemPadding="4em"
                                                    value={values.userTypeId ? values.userTypeId : 0}
                                                    onChange={handleInputChange}
                                                    items={userTypes?.map(ut => ({ id: ut.id, title: ut.name }))}
                                                    error={errors.userTypeId}
                                                />
                                            }
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {values.userType?.hasAffiliateRestriction &&
                                    <Grid item sm={12}>
                                        <Paper sx={{ padding: '2em', borderRadius: '.5em' }} variant='outlined'>
                                            <Grid item>
                                                <Grid container columnSpacing={3}>
                                                    <Grid item>
                                                        <SectionLabel>Select User 'Affiliate(s)'</SectionLabel>
                                                        <SubTitle>{ affiliates?.length ?? 0} Affiliates</SubTitle>
                                                    </Grid>
                                                    <Grid item>
                                                        <SearchControl placeholderText="Search affiliates" onSearch={handleAffiliateSearchClick} onReset={handleAffiliateSearchResetClick} />
                                                    </Grid>
                                                    <Grid item sm />
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                                {errors.affiliates === "" ? <></> : <ErrorLabel>{errors.affiliates}</ErrorLabel>}
                                            </Grid>
                                            <Grid item sm={12} sx={{ padding: '0em 1em' }}>
                                                {values.userType?.operateMultipleAffilites ?
                                                    (
                                                        <FormGroup>
                                                            <Grid container>
                                                                {affiliates?.map(item => (
                                                                    <Grid item sm={6} md={6} lg={4} key={item.affiliateId}>
                                                                        <Checkbox value={item.affiliateId} onChange={handleAffiliateCheckboxSelect}
                                                                            checked={values.affiliates ? values.affiliates.some(a => a?.affiliateId === item.affiliateId) : false}
                                                                            label={item.affiliateName}
                                                                            color={(!item.isActive && !item.active) ? "#6e6e6e" : null}
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </FormGroup>
                                                    ) : (
                                                        <RadioGroup name="affilliateId" itemPadding="4em"
                                                            value={(values?.affiliates && values?.affiliates[0]?.affiliateId) ? values.affiliates[0].affiliateId : 0}
                                                            onChange={handleAffiliateRadioSelect}
                                                            items={affiliates?.map(a => ({ id: a.affiliateId, title: a.affiliateName, isActive: a.isActive }))}
                                                            error={errors.userTypeId}
                                                            allowDisplayInactiveAsDisabled={true}
                                                        />
                                                    )}
                                            </Grid>
                                            <Grid item md={12} sm={12} sx={{ paddingRight: '1em' }}>
                                                <TablePagination component="div" sx={{ bgcolor: 'rgba(93, 184, 252, 0.1)', padding: '0em 0 4em 0 ', borderRadius: '10px' }}
                                                    count={totalAffilaites ?? 0} page={affiliatePage}
                                                    rowsPerPage={affiliatesPerPage}
                                                    onPageChange={handleAffilaitePageChange}
                                                    rowsPerPageOptions={[12, 15, 30, 60]}
                                                    onRowsPerPageChange={handleAffiliateRowsPerPageChange}
                                                />
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                }
                                <Grid item sm={12} sx={{ marginTop: "1em" }}>
                                    <Grid container>
                                        <Grid item md={4} sm={6}>
                                            <Button name="step0_personalInfo_button" onClick={handleAddPersonalInfoClick} text="Add Personal Information" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    ) : (activeStep === 1 ? (
                        <Form>
                            <Grid container sx={{ padding: '0 2em' }}>
                                <Grid item sm={12}>
                                    <Paper sx={{ margin: '1em', padding: '2em', borderRadius: '.5em' }} variant='outlined'>
                                        <Grid item sx={{ paddingBottom: '1em' }}>
                                            <SectionLabel>Add Personal Information</SectionLabel>
                                        </Grid>
                                        <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                            {userNameAvailableResp ? (userNameAvailable ? (<SuccessLabel>Username "{availCheckedUserName}" is Available!</SuccessLabel>) : (<ErrorLabel>Username "{availCheckedUserName}" not Available!</ErrorLabel>)) : (<></>)}

                                            <Grid container spacing={2}>
                                                <Grid item md={4} sm={5}>
                                                    <FormLabel>Username</FormLabel>
                                                    <Input name="userName" value={values.userName} onChange={handleInputChange} disabled={editMode}
                                                        error={errors.userNameError} />
                                                </Grid>
                                                <Grid item md={4} sm={6.5}>
                                                    <br />
                                                    {!editMode &&
                                                        <Button disabled={errors.userNameError?.length > 1} onClick={handleCheckUserNameAvailabilityClick} text='Check "Username" Availability' />
                                                    }
                                                </Grid>
                                                <Grid item md={4} sm={0} />
                                                <Grid item md={4} sm={5}>
                                                    <FormLabel>First name</FormLabel>
                                                    <Input name="firstName" value={values.firstName} onChange={handleInputChange}
                                                        error={errors.firstName} />
                                                </Grid>
                                                <Grid item md={4} sm={5}>
                                                    <FormLabel>Last name</FormLabel>
                                                    <Input name="lastName" value={values.lastName} onChange={handleInputChange}
                                                        error={errors.lastName} />
                                                </Grid><Grid item md={4} />
                                                <Grid item md={4} sm={5}>
                                                    <FormLabel>Email</FormLabel>
                                                    <Input name="email" value={values.email} onChange={handleInputChange}
                                                        error={errors.email} />
                                                </Grid>
                                                <Grid item md={4} sm={5}>
                                                    <FormLabel>Phone number <span style={{ fontSize: "smaller" }}>(Optional)</span></FormLabel>
                                                    <PhoneNumber name="phoneNumber" value={values.phoneNumber} onChange={handleInputChange}
                                                        error={errors.phoneNumber} />
                                                </Grid><Grid item md={4} />
                                                {   configInfo.AllowExplicitAgentId && 
                                                    <>
                                                        <Grid item md={4} sm={5}>
                                                            <FormLabel>Affiliate agent ID</FormLabel>
                                                            <Input name="affiliateAgentId" value={values.affiliateAgentId} onChange={handleInputChange}
                                                                error={errors.affiliateAgentId} />
                                                        
                                                        </Grid><Grid item md={4} sm />
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item sm={12} sx={{ margin: '1em 1em 0 1em' }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} sm={7}>
                                            <Button onClick={() => setActiveStep(0)} text="View/Edit User Type and Affiliate(s)" variant="outlined" />
                                        </Grid>
                                        <Grid item md={4} sm={5}>
                                            <Button onClick={handleViewProfileSummaryClick} text="View Profile Summary" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    ) : (
                        <Form>
                            <Grid container sx={{ padding: '0 2em' }}>
                                <Grid item sm={12}>
                                    <Paper sx={{ margin: '1em', padding: '1.5em', borderRadius: '.5em' }} variant='outlined'>
                                        <Grid item sx={{ paddingBottom: '1em' }}>
                                            <SectionLabel>Selected User'Type'</SectionLabel>
                                        </Grid>
                                        <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                            <FormControlLabel control={<Radio checked={true} />}
                                                label={<Typography sx={{ fontWeight: "bold", color: "#003561" }}>{values.userType?.name}</Typography>} />
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {values.userType?.hasAffiliateRestriction &&
                                    <Grid item sm={12}>
                                        <Paper sx={{ margin: '1em', padding: '1.5em', borderRadius: '.5em' }} variant='outlined'>
                                            <Grid item sx={{ paddingBottom: '1em' }}>
                                                <SectionLabel>Selected Affiliate(s)</SectionLabel>
                                            </Grid>
                                            <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                                <FormGroup>
                                                    <Grid container>
                                                        {values?.affiliates?.map(a => (
                                                            <Grid key={a.affiliateId} item sm={4}>
                                                                <Checkbox key={a.affiliateId} checked={true} label={a.affiliateName}
                                                                color={(!a.active && !a.isActive) ? "#6e6e6e" : null}/> 
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </FormGroup>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                }
                                <Grid item sm={12}>
                                    <Paper sx={{ margin: '1em', padding: '1em', borderRadius: '.5em' }} variant='outlined'>
                                        <Grid item sx={{ paddingBottom: '1em' }}>
                                            <SectionLabel>Personal Information</SectionLabel>
                                        </Grid>
                                        <Grid item sm={12} sx={{ padding: '0 1em' }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={2} sm={3}>
                                                    <FormValue>Username</FormValue>
                                                    <FormLabel>{values.userName}</FormLabel>
                                                </Grid>
                                                <Grid item md={2} sm={4}>
                                                    <FormValue>First name</FormValue>
                                                    <FormLabel>{values.firstName}</FormLabel>
                                                </Grid>
                                                <Grid item md={2} sm={5}>
                                                    <FormValue>Last name</FormValue>
                                                    <FormLabel>{values.lastName}</FormLabel>
                                                </Grid>
                                                <Grid item md={4} sm={5}>
                                                    <FormValue>Email</FormValue>
                                                    <FormLabel>{values.email}</FormLabel>
                                                </Grid><Grid item md sm />
                                                {values.phoneNumber &&
                                                    <Grid item md={2} sm={5}>
                                                        <FormValue>Phone number</FormValue>
                                                        <FormLabel>{values.phoneNumber}</FormLabel>
                                                    </Grid>
                                                }
                                                {(configInfo.AllowExplicitAgentId && values.affiliateAgentId) &&
                                                    <Grid item md={2} sm={5}>
                                                        <FormValue>Affiliate agent ID</FormValue>
                                                        <FormLabel>{values.affiliateAgentId}</FormLabel>
                                                    </Grid>}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container spacing={2} sx={{ margin: ".5em 0" }}>
                                        <Grid item sm={4}>
                                            <Button onClick={() => setActiveStep(1)} text="View/Edit Personal Information" variant="outlined" />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Button disabled={updateUserApiReq.inprogress || createUserApiReq.inprogress} onClick={handleCreateUserClick} text={editMode ? "Update User" : "Create User"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    ))}
                </>
            </Grid >
        </Grid >
    );
}

export default CreateEditUser;