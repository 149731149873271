import React from 'react';

export const getDefaultContext = () => ({
  userManagementInfo: {
    userSearchCriteria: "",
    rowsPerPage: null
  }
});

const setDefaultContext = {
  setUserManagementInfo: () => {},
  resetUserManagementInfo: () => {}
};

export default React.createContext({ ...getDefaultContext(), ...setDefaultContext });