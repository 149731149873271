import React, { useEffect, useContext, useState } from "react";
import { Alert, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Password } from "../components/formFields";
import { ROUTES } from "../utils/consts";
import { MainTitle } from "../components/styledComponets/Titles";
import { loginApi } from "../api/loginApi";
import GlobalContext from "../context/globalContext/globalContext";
import { clearAuthToken, isLoggedIn } from "../utils/authHelper";
import { useForm } from "../utils/hooks/useForm";
import { ErrorLabel, FormLabel } from "../components/styledComponets/Labels";
import useRequest from "../utils/hooks/useRequest";
import ConfigContext from "../context/configContext/configContext";

function Login() {
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(GlobalContext);
    const { configInfo, setConfigInfo } = useContext(ConfigContext)
    const [isUserInactive, setIsUserInactive] = useState(false);
    const loginApiReq = useRequest(loginApi);

    const initialFormValue = { userName: '', password: '' }

    const validateForm = (fieldValues = values) => {
        let temp = { ...errors }
        if ('userName' in fieldValues)
            temp.userName = fieldValues.userName ? "" : "This field is required."
        if ('password' in fieldValues)
            temp.password = fieldValues.password ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValue, true, validateForm);

    const setContextInfo = (userInfo, configInfo) => {
        setUserInfo(userInfo);
        setConfigInfo(configInfo);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            loginApiReq.request(values, setContextInfo);
        }
    }

    useEffect(() => {
        if (userInfo.userName || isLoggedIn()) {
            switch (userInfo.nextAction) {
                case 'CHANGE_PASSWORD':
                    navigate(ROUTES.CHANGE_PASSWORD_ROUTE);
                    break;
                case 'SECURITY_QUESTION_REQUIRED':
                    navigate(ROUTES.SECURITY_QUESTION_ROUTE);
                    break;
                case 'MUST_ACTIVATE':
                    clearAuthToken();
                    setIsUserInactive(true);
                    break;
                default:
                    navigate(ROUTES.HOME_ROUTE);
                    break;
            }
        }
    }, [userInfo.expiery]);

    return (
        <>
            <Form>
                <Grid sx={{marginBottom:"1em"}}>
                    {isUserInactive &&
                        <Alert sx={{ margin: "0 -4.6em 0 -6.9em" }} severity="error" variant="filled" onClose={(e) => { setIsUserInactive(false); clearAuthToken(); }}>
                            Your account has been <strong>deactivated</strong>, please contact your <strong>supervisor</strong>, or email your <strong>Charter Point of Contact</strong> to activate your account
                        </Alert>}
                </Grid>
                <Grid container rowSpacing={3} sx={{ opacity: isUserInactive ?0.5:1}}>
                    <Grid item md={6} sm={10}>
                        <MainTitle sx={{ marginTop: "1em" }}>Login to your account</MainTitle>
                        <hr />
                    </Grid><Grid item md={4} sm />
                    {userInfo.isError &&
                        <Grid item md={12} sm={12} sx={{ paddingTop: '0' }}>
                            <ErrorLabel>Incorrect Username or Password.</ErrorLabel>
                            <Typography variant="subtitle2">Try entering your information again, or <Link to={{ pathname: '/forgotpassword' }}>reset</Link> your login credentials</Typography>
                        </Grid>
                    }
                    <Grid item md={4} sm={8}>
                        <FormLabel>Username</FormLabel>
                        <Input name="userName" value={values.userName} onChange={handleInputChange} disabled={isUserInactive}
                            error={errors.userName} />
                    </Grid><Grid item md={7} sm />
                    <Grid item md={4} sm={8}>
                        <FormLabel>Password</FormLabel>
                        <Password name="password" value={values.password} onChange={handleInputChange} disabled={isUserInactive}
                            error={errors.password} />
                    </Grid><Grid item md={8} sm={3} />
                    <Grid item md={6} sm={10}>
                        <Link to="/forgotpassword" sx={{ marginBottom: '2em' }}><FormLabel>Forgot Password?</FormLabel></Link>
                        <hr />
                    </Grid><Grid item md={6} sm />
                    <Grid item md={4} sm={8}>
                        <Button type="submit" onClick={handleSubmit} text="Login" disabled={isUserInactive} />
                    </Grid>
                </Grid>
            </Form>
        </>
    );
}

export default Login;