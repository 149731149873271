import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';


// Change style configs here to apply changes all over the application
// refer: https://bareynol.github.io/mui-theme-creator/
const baseTheme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: '#005a84',
            dark: '#003651',
            light: '#FFF',
            neutral: '#787878',
        },
        secondary: {
            main: '#00629B',
            light: '#005a84',
            dark: '#ededed',
        },
        headerAndFooter: '#00629B',
    },
    typography: {
        fontFamily: 'SpectrumSans',
        h4: {
            fontWeight: 500,
            marginTop: 50,
        },
        button: {
            textTransform: 'none',
            fontWeight: 'normal'
        }
    },
    overrides: {
        MuiFormLabel: {
            asterisk: {
                fontSize: '18px',
                fontWeight: 600,
                color: '#f44336',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                backgroundColor: 'rgba(0, 0, 0, 0.87)',
            },
            arrow: {
                color: 'rgba(0, 0, 0, 0.87)',
            },
        },
    },
}));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={baseTheme}>
            <CssBaseline />
            <SnackbarProvider>
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>
);
