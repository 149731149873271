import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls";

export async function getSecurityQuestions(setResponse) {
    const ops = {
        method: 'get',
        url: URLs.GET_SECURITY_QUESTIONS,
        ...getApiOptions(),
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function updateSecurityQuestions(payload,setResponse) {
    const ops = {
        method: 'post',
        url: URLs.GET_SECURITY_QUESTIONS,
        data: payload,
        ...getApiOptions(),
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}