import axios from "axios";
import URLs from "../utils/urls";
import { getApiOptions } from "../utils/apiHelpers";

export async function getLocationDetailsByAddress(params,setResponse) {
    const endpointUrl = `${URLs.LOCATION_SEARCH}/search`;
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        params: params
    };
    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}